import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchStores, fetchStore } from "../../thunks/storesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.storeBranches);
const storeBranchesSliceDatasource = createSlice({
	name: "datasources/storeBranches",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (storeBranches, _) => {
			dataAdapter.removeAll(storeBranches, {});
		}
	},
	extraReducers: builder => {
		builder
			.addMatcher(isAnyOf(fetchStores.fulfilled, fetchStore.fulfilled), (storeBranches, action) => {
				if (action.payload.hasOwnProperty("storeBranches")) {
					dataAdapter.setAll(storeBranches, action.payload.storeBranches);
				}
			});
	}
});
export default storeBranchesSliceDatasource.reducer;

export const unloadStoreBranches = () => storeBranchesSliceDatasource.actions.unloaded();
export const getStoreBranches = dataView.selectAll;