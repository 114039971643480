import { createSlice } from "@reduxjs/toolkit";
import { configure } from "../../../config/app";
import { signIn } from "../../thunks/waitersThunk";

const defaultValue = { id: 0, nik: "", name: "", address_line_1: "", address_line_2: "", mobile: "", email: "", asset_url: "" };
const initialState = localStorage.getItem(configure.StorageKey.Waiter) === null ? defaultValue : JSON.parse(localStorage.getItem(configure.StorageKey.Waiter));
const Authentication = createSlice({
	name: "auth/waiter",
	initialState: initialState,
	reducers: {
		signedOut: (waiter, _) => {
			waiter.id = 0;
			waiter.nik = "";
			waiter.name = "";
			waiter.address_line_1 = "";
			waiter.address_line_2 = "";
			waiter.mobile = "";
			waiter.email = "";
            waiter.asset_url = "";
		}
	},
	extraReducers: {
		[signIn.fulfilled]: (waiter, action) => {
			waiter.id = action.payload.waiter.id;
			waiter.nik = action.payload.waiter.nik;
			waiter.name = action.payload.waiter.name;
			waiter.address_line_1 = action.payload.waiter.address_line_1;
			waiter.address_line_2 = action.payload.waiter.address_line_2;
			waiter.mobile = action.payload.waiter.mobile;
			waiter.email = action.payload.waiter.email;
            waiter.asset_url = action.payload.waiter.asset_url;
		},
	}
});
export const { signedOut } = Authentication.actions;
export default Authentication.reducer;

export const signOut = () => Authentication.actions.signedOut();