import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchPaymentCategories } from "../../thunks/paymentCategoriesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.paymentCategories);
const paymentCategoriesSliceDatasource = createSlice({
	name: "datasources/paymentCategories",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (paymentCategories, _) => {
			dataAdapter.removeAll(paymentCategories, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPaymentCategories.fulfilled, (paymentCategories, action) => {
				if (action.payload.hasOwnProperty("paymentCategories")) {
					dataAdapter.setAll(paymentCategories, action.payload.paymentCategories);
				}
			});
	}
});
export default paymentCategoriesSliceDatasource.reducer;

export const unloadPaymentCategories = () => paymentCategoriesSliceDatasource.actions.unloaded();
export const getPaymentCategories = dataView.selectAll;