import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { paginate } from "../../../helpers/paginate";
import SmallPaginator from "../../elements/smallPaginator";

const FormVoidConfirmation = ({ showDialog, title, voidReasons, handleVoid, handleCloseDialog }) => {

	const [currentPage, setCurrentPage] = React.useState(1);
	const [pageSize] = React.useState(16);
	const pagesCount = Math.ceil(voidReasons.length / pageSize);
    const [voidReasonId, setVoidReasonId] = React.useState(0);

    React.useEffect(() => {
        setVoidReasonId(0);
    }, [showDialog]);

	const getPagedVoidReasons = () => {
		return paginate(voidReasons, currentPage, pageSize);
	}

	const handleOnPrev = () => {
		setCurrentPage(currentPage - 1 <= 0 ? 1 : currentPage - 1);
	}
	
	const handleOnNext = () => {
		setCurrentPage(currentPage + 1 > pagesCount ? pagesCount : currentPage + 1);
	}
    
    const handleApply = () => {
        if (voidReasonId === 0) {
            return;
        }

        handleVoid(voidReasonId);
    }

    return (  
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={showDialog} onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-16 px-16 pb-80 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-1/2">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">{title}</h3>
                                </div>
                                <div className="card-body">
                                    <div className="classic card h-full">
				                        <div className="card-header bg-gray-100 h-65">
                                            <h3 className="flex card-title items-center h-40">
                                                Void Reason
                                            </h3>
                                            <div className="ml-auto">
                                                <SmallPaginator	
                                                    itemsCount={voidReasons.length} 
                                                    pageSize={pageSize}
                                                    currentPage={currentPage}
                                                    handleOnPrev={handleOnPrev}
                                                    handleOnNext={handleOnNext}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="grid grid-cols-4 grid-rows-4 grid-flow-row gap-4">
                                                {getPagedVoidReasons().map(voidReason => 
                                                    <div key={voidReason.id}>
                                                        <input type="radio" className="btn-check" id={`voidReason${voidReason.id}`} name="voidReasonId" value={voidReason.id} onChange={e => setVoidReasonId(e.target.value)} />
                                                        <label className="btn btn-outline-primary w-full h-70" htmlFor={`voidReason${voidReason.id}`}>{voidReason.reason}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary uppercase" onClick={handleApply}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormVoidConfirmation;