import React from "react";
import Cleave from 'cleave.js/react';
import { Dialog, Transition } from "@headlessui/react";

const FormEndDay = ({showDialog, openingBalance, totalCashReceived, handleEndDay, handleCloseDialog}) => {
    
    const [info, setInfo] = React.useState({ openingBalance:0, totalCashReceived: 0 });
    const [actualCashBalance, setActualCashBalance] = React.useState(0);
    const [description, setDescription] = React.useState("");

    React.useEffect(() => {
        setInfo({ openingBalance, totalCashReceived });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog]);

    const handleApplyEndDay = () => {
        handleEndDay(actualCashBalance, description);
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-2/5">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">End Day</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group" style={{ marginBottom: "5px"}}>
                                        <div className="flex">
                                            <div className="w-1/2 mr-10">
                                                <label className="form-label">Opening Balance</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={info.openingBalance} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/2 mr-10">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ marginBottom: "5px"}}>
                                        <div className="flex">
                                            <div className="w-1/2 mr-10">
                                                <label className="form-label">Total Cash Received</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={info.totalCashReceived} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/2 mr-10">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ marginBottom: "5px"}}>
                                        <div className="flex">
                                            <div className="w-1/2 mr-10">
                                                <label className="form-label">Expected Cash Balance</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={Number(info.openingBalance) + Number(info.totalCashReceived)} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/2 mr-10">
                                                <label className="form-label">Actual Cash Balance</label>
                                                <Cleave className="form-control form-control-lg text-right" value={actualCashBalance} onChange={e => setActualCashBalance(e.target.rawValue)} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="description">Description</label>
                                        <textarea id="description" name="description" type="text" className="form-control" value={description} onChange={e => setDescription(e.target.value)} rows="5" resize="none" />
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button className="btn btn-outline-danger" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Close&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary" onClick={handleApplyEndDay}>
                                        &nbsp;&nbsp;End Day&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormEndDay;