import React from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const CompactSidebar = ({ navigationItems, handleClick }) => {

    const handleItemClick = (event, item) => {
        event.preventDefault();
        handleClick(item);
    }

    return ( 
        <div className="compact-sidebar">
            <div className="sidebar-layout sidebar-layout-dark">
                <div className="items">
                    <NavLink to="/dashboard" className="link">
                        <i className="icon las la-laptop" />
                        <span className="title">Dashboard</span>
                    </NavLink>
                    <NavLink to="/sales-invoices" className="link">
                        <i className="icon las la-file-invoice-dollar" />
                        <span className="title">Sales</span>
                    </NavLink>
                    <NavLink to="/shift" className="link" onClick={e => handleItemClick(e, "shift")}>
                        <i className="icon las la-clock" />
                        <span className="title">Shift</span>
                    </NavLink>
                    {/*
                        <NavLink to="/configure" className="link">
                            <i className="icon las la-tools" />
                            <span className="title">Configure</span>
                        </NavLink>
                    */}
                </div>
            </div>
            
            <div className={`detail ${navigationItems.shift && "open"}`}>
                <PerfectScrollbar>
                    <div className="detail-items">
                        <NavLink to="/shift/operation/current"><span className="las la-power-off"/>Day Start / End</NavLink>
                        <NavLink to="/shift/logs"><span className="las la-history"/>Shift Logs</NavLink>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );
}
 
export default CompactSidebar;