import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const FormChangeShiftConfirmation = ({ showDialog, handleChangeShift, handleCloseDialog }) => {

    return (  
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={showDialog} onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-16 px-16 pb-80 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-16 pt-520 pb-16 sm:p-24 sm:pb-16">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-48 w-48 rounded-full bg-blue-100 sm:mx-0 sm:h-40 sm:w-40">
                                        <i className="las la-question text-blue-600 text-4xl" />
                                    </div>
                                    <div className="mt-12 text-center sm:mt-0 sm:ml-16 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            End Shift
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to end this shift ?
                                            </p>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-100 px-14 py-12 text-right">
                                <button type="button" className="btn btn-outline-danger" onClick={handleCloseDialog}>
                                    &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary" onClick={() => handleChangeShift(false)} autoFocus>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-primary" onClick={() => handleChangeShift(true)} autoFocus>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yes, and Print&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormChangeShiftConfirmation;