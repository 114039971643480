import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/storeEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchStores = createAsyncThunk(
	"datasources/stores/fetchStores",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/stores/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.stores);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The stores could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchStore = createAsyncThunk(
	"datasources/stores/fetchStore",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/stores/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.store);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The store could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveStore = createAsyncThunk(
	"datasources/stores/saveStore",
	async (request, thunkAPI) => {
		try {
			const params = { params: {withStoreDocumentSettings: true} };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/stores/view", request, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The store has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The store could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);
