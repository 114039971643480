import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchStores, fetchStore, saveStore } from "../../thunks/storesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.stores);
const Datasource = createSlice({
	name: "datasources.stores",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (stores, _) => {
			dataAdapter.removeAll(stores, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchStores.fulfilled, (stores, action) => {
				if (action.payload.hasOwnProperty("stores")) {
					dataAdapter.setAll(stores, action.payload.stores);
				}
			})
			.addCase(fetchStore.fulfilled, (store, action) => {
				if (action.payload.hasOwnProperty("store")) {
					dataAdapter.upsertMany(store, action.payload.store);
				}
			})
			.addCase(saveStore.fulfilled, (store, action) => {
				dataAdapter.upsertOne(store, action.payload.store);
			});
	}
});
export default Datasource.reducer;

export const unloadStores = () => Datasource.actions.unloaded();
export const getStores = dataView.selectAll;
export const getStoreEntities = dataView.selectEntities;
export const getStore = id => createSelector(
	state => dataView.selectById(state, id),
	store => store
);