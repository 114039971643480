import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import Cleave from "cleave.js/react";

const PaymentTypeCard = ({ showDialog, subTotal, handleAddPayment, handleCloseDialog }) => {

    const [amount, setAmount] = React.useState(subTotal);
    const [cardNumber, setCardNumber] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    const [accountName, setAccountName] = React.useState("");
    const [approvalCode, setApprovalCode] = React.useState("");

    const handleApplyPayment = () => {
        if (cardNumber.trim() === "" || approvalCode.trim() === "") {
            return;
        }

        handleAddPayment(cardNumber, accountName, approvalCode, amount, "");
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-2/5">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Card Payment</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <div className="flex">
                                            <div className="w-1/2 mr-20">
                                                <label className="form-label" htmlFor="outstanding">Outstanding</label>
                                                <Cleave id="outstanding" name="outstanding" type="text" className="form-control form-control-lg text-right" value={subTotal} disabled={true} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/2 ml-2">
                                                <label className="form-label">Card Amount</label>
                                                <Cleave id="amount" name="amount" type="text" className="form-control form-control-lg text-right" value={amount} onChange={e => setAmount(e.target.rawValue)} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="flex">
                                            <div className="w-1/2 mr-20">
                                                <label className="form-label" htmlFor="cardNumber"><span className="text-red-500">*</span>Card Number</label>
                                                <Cleave id="cardNumber" name="cardNumber" type="text" className="form-control form-control-lg" placeholder="xxxx xxxx xxxx xxxx" value={cardNumber} onChange={e => setCardNumber(e.target.value)} options={{creditCard: true}} />
                                            </div>
                                            <div className="w-1/2 ml-2">
                                                <label className="form-label" htmlFor="verificationCode"><span className="text-red-500">*</span>Verification Code</label>
                                                <input id="verificationCode" name="verificationCode" type="text" className="form-control form-control-lg" placeholder="Verification / Approval code from EDC" value={approvalCode} onChange={e => setApprovalCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="flex">
                                            <div className="w-1/2 mr-20">
                                                <label className="form-label" htmlFor="bankName">Bank Name</label>
                                                <input id="bankName" name="bankName" type="text" className="form-control form-control-lg" placeholder="Name of the bank that issued the card" value={bankName} onChange={e => setBankName(e.target.value)} />
                                            </div>
                                            <div className="w-1/2 ml-2">
                                                <label className="form-label" htmlFor="accountName">Account Name</label>
                                                <input id="accountName" name="accountName" type="text" className="form-control form-control-lg" placeholder="Name on the card" value={accountName} onChange={e => setAccountName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary uppercase" onClick={handleApplyPayment}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    );
}
 
export default PaymentTypeCard;