import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import NumberControl from "../../elements/numberControl";

const FormPax = ({ showDialog, currentPax, handleUpdatePax, handleCloseDialog }) => {

    const [pax, setPax] = React.useState(null);

    React.useEffect(() => {
        setPax(currentPax);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog]);


    const handleInputChange = element => {
		const { value } = element.target
		setPax(value);
	}

    const handleApplyUpdate = () => {
        handleUpdatePax(pax);
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-1/4">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Number of pax</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mx-auto mt-20 w-100">
                                        <input id="pax" name="pax" type="text" className="form-control form-control-lg text-center" value={pax} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group mx-auto mt-20 text-center">
                                        <NumberControl perPage={5} maxValue={50}  handleClick={pax => setPax(pax)} />
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary uppercase" onClick={handleApplyUpdate}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormPax;