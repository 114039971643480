import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "shifts" : "shift";
	const shiftDetailsEntity = new schema.Entity("shiftDetails");
	const shiftEntity = new schema.Entity(
		dataset,
		{shift_details: [shiftDetailsEntity]}
	);
	const result = normalize(
		datasources, 
		isArray ? [shiftEntity] : shiftEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.shifts;
	const dataset = isArray ? "shifts" : "shift";
	const shiftEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.shifts : datasources.shift;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [shiftEntity] : shiftEntity }, 
		{ [dataset]: entities }
	);
}