import moment from "moment";

const DataTable = ({ salesInvoices, salesOrderEntities, diningTableEntities, handlePrintBill }) => {

    return (
        <table className="table table-striped table-hover text-14">
            <thead>
                <tr>
                    <th className="text-left">Bill Number</th>
                    <th className="text-left">Order Number</th>
                    <th className="text-left">Date</th>
                    <th className="text-left">Table</th>
                    <th className="text-right" width="10%">Pax</th>
                    <th className="text-right">Grand Total</th>
                    <th width="5%"></th>
                </tr>
            </thead>
            <tbody>
                {salesInvoices.map(salesInvoice => 
					<tr key={salesInvoice.id} className="cursor-pointer">
						<td>{salesInvoice.code}</td>
						<td>{salesOrderEntities[salesInvoice.sales_order].code}</td>
						<td>{moment(salesInvoice.invoice_date).format("DD-MM-YYYY hh:mm")}</td>
						<td>{diningTableEntities[salesInvoice.dining_table]?.number}</td>
						<td className="text-right">{Number(salesOrderEntities[salesInvoice.sales_order]?.pax).toLocaleString()}</td>
						<td className="text-right">{Number(salesInvoice.grand_total).toLocaleString()}</td>
						<td>
                            <button className="btn btn-primary w-auto text-base" style={{ display: "inline-block" }} onClick={() => handlePrintBill(salesInvoice.id)}>
                                <i className="fas fa-print" />
                                Print
                            </button>
                        </td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;