import React from "react";

const Numpad = ({ handleClick }) => {
    return (
        <React.Fragment>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("1")}>1</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("2")}>2</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("3")}>3</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("4")}>4</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("5")}>5</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("6")}>6</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("7")}>7</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("8")}>8</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("9")}>9</button>
                </div>
                <div className="col-span-2">
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("0")}>0</button>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary uppercase h-50 w-full" style={{display: "inline-block"}} onClick={() => handleClick("clr")}>CLR</button>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default Numpad;