import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchSalesOrders, fetchSalesOrder } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const customerSliceDatasource = createSlice({
	name: "datasources/customers",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (customers, _) => {
			dataAdapter.removeAll(customers, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesOrders.fulfilled, (customers, action) => {
				if (action.payload.hasOwnProperty("customer")) {
					dataAdapter.setAll(customers, action.payload.customer);
				}
			})
			.addCase(fetchSalesOrder.fulfilled, (customer, action) => {
				if (action.payload.hasOwnProperty("customer")) {
					dataAdapter.setAll(customer, action.payload.customer);
				}
			});
	}
});
export default customerSliceDatasource.reducer;

export const unloadCustomers = () => customerSliceDatasource.actions.unloaded();