import React from "react";
import PageHeader from "../elements/pageHeader";
import CompactSidebar from "../elements/compactSidebar";
import OverlayPage from "../elements/overlayPage";
import PageFooter from "../elements/pageFooter";

const Main = props => {
	
	const defaultNavigationItems = { shift: false, report: false };
	const [navigationItems, selectedNavigationItem] = React.useState(defaultNavigationItems);

	const handleClick = selectedItem => {
		selectedNavigationItem({ 
			...defaultNavigationItems, 
			[selectedItem]: !navigationItems[selectedItem] 
		});
	}

	const hadleResetActiveNavigationItem = () => {
		selectedNavigationItem({ ...defaultNavigationItems });
	}

    return ( 
		<React.Fragment>
			<PageHeader />
			<CompactSidebar navigationItems={navigationItems} handleClick={handleClick} />
			{(navigationItems.shift || navigationItems.report) && <OverlayPage handleClick={hadleResetActiveNavigationItem} />}
			<div className="main-content h-full">
				<div className="page-content h-full overflow-y-scroll" style={{padding: "130px 0px 60px 12px"}}>
					{props.children}
				</div>
				<PageFooter />
			</div>
	 	</React.Fragment>
    );
}
 
export default Main;