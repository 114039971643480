const isDev =  () => !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const configure = {
	ApiClientURL: isDev() ? "http://localhost:8000/v1/" : "https://fsr.service.findproject.net/v1/",
	StorageKey: {
		Vendor: "vendor",
		CurrentStore: "current-store",
		CurrentStoreBranch: "current-store-branch",
		Waiter: "waiter",
		IsStoreOpen: 'store-open'
	}
}