import React from "react";

const Unauthorized = props => {

	React.useEffect(() => {
        document.body.classList.add('full-height');
    }, []);

    return ( 
        <React.Fragment>
			<div className="flex w-full h-full p-125">
				{props.children}
			</div>
		</React.Fragment>
    );
}

export default Unauthorized;