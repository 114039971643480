import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/menuCategoryEntity";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchMenuCategories = createAsyncThunk(
	"datasources/menuCategories/fetchMenuCategories",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/menu-categories/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.menuCategories);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The menu category could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);