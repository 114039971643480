import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/voidReasonEntity";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchVoidReasons = createAsyncThunk(
	"datasources/voidReasons/fetchVoidReasons",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/void-reasons/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.voidReasons);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The void reason could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);