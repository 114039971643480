import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStores } from "../../../store/thunks/storesThunk";
import { selectStore } from "../../../store/slices/authentications/storeSliceAuthentication";
import { logout } from "../../../store/slices/authentications/vendorSliceAuthentication";
import { getStoreBranches, unloadStoreBranches } from "../../../store/slices/datasources/storeBranchesSliceDatasource";
import { getStoreEntities, unloadStores } from "../../../store/slices/datasources/storesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";

const Stores = props => {
    const history = useHistory();
	const dispatch = useDispatch();
	const { setLoading } = props;
	const stores = useSelector(getStoreEntities);
	const storeBranches = useSelector(getStoreBranches);

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreBranches());
            dispatch(unloadStores());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchStores(getQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]); 
	
    const getQueryFilter = () => {
        const queryFilter = {
            withStoreBranches: true
        };

        return {
            query: queryFilter
        };
    }

	const handleSelectStore = (storeBranch) => {
        const store = stores[storeBranch.store_id];
		dispatch(selectStore({ store, storeBranch }));
		history.push('/dashboard');
	}

    const handleLogout = () => {
        dispatch(logout())
        history.replace('/login');
    }

    return ( 
        <div className="flex flex-row flex-grow justify-between">
            <div className="flex cover"></div>
            <div className="flex w-1/4">
                <div className="authentication-page pt-80 px-48">
                    <div className="text-center">
                        <img src={require("../../assets/images/logo.png").default} alt="logo" />
                    </div>
                    <h5 className="mb-0">Your FSR Stores!</h5>
				    <p className="font-size-14 text-muted mb-16">Select to managing the store!</p>
                    {storeBranches.map((storeBranch, index) => 
                        <React.Fragment key={storeBranch.id}>
                            <div className="flex items-center py-20 cursor-pointer" onClick={() => handleSelectStore(storeBranch)}>
                                <div className="flex items-center justify-center w-48 h-48 rounded-full bg-gray-200 border border-gray-600 text-xl text-gray-600 mr-12">
                                    <i className="las la-utensils text-4xl" />
                                </div>
                                <div>
                                    <h5>{storeBranch.name}</h5>
                                </div>
                            </div>
                            {index < storeBranch.length - 1  && <hr />}
                        </React.Fragment>
                    )}
                    <div className="row mt-40">
                        <button className="btn btn-primary mr-8 uppercase" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default WithLoadingPage(Stores);