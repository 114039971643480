import React from "react";

const DiningTableSections = ({ diningTableSections, handleSelectDinignTableSection }) => {

    return (
        <React.Fragment>
            <div className="flex flex-row pl-5">
                <button className="btn btn-primary ml-5 mt-5 mr-2 text-center uppercase h-70 w-120" style={{ display: "inline-block" }}>
                    Quick Service
                </button>
                {diningTableSections.map(diningTableSection => 
                    <button key={diningTableSection.id} className="btn btn-primary ml-5 mt-5 mr-2 text-center uppercase h-70 w-120" style={{ display: "inline-block" }} onClick={() => handleSelectDinignTableSection(diningTableSection.id)}>
                        {diningTableSection.name}
                    </button>
                )}
            </div>
        </React.Fragment>
    );
}
 
export default DiningTableSections;