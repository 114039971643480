import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Cleave from "cleave.js/react";
import { fetchCurrentShift, startDay } from "../../../store/thunks/shiftsThunk";
import { unloadShifts } from "../../../store/slices/datasources/shiftsSliceDatasource";
import { getCurrentStoreBranch, getWaiter, isStoreOpen } from "../../helpers/authentication";
import WithLoadingPage from "../commons/withLoadingPage";

const ShiftForm = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: storeBranchId, name: storeBranchName } = getCurrentStoreBranch();
    const { name: waiterName } = getWaiter();
	const { setLoading } = props;
    const [request, setRequest] = React.useState({ store_branch_id: storeBranchId, start_by: waiterName, opening_cash_balance: "" });
    const [error, setError] = React.useState({ start_by: null, opening_cash_balance: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadShifts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {	
        async function initialize() {
            setLoading(true);
            await dispatch(fetchCurrentShift());
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]); 

    const handleInputChange = (element) => {
		const { name, rawValue } = element.target
		setRequest({...request, [name]: rawValue});
	}

    const handleFormSubmit = async (event) => {
		event.preventDefault();
        setLoading(true);
        await dispatch(startDay(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, start_by: "", opening_cash_balance: "" });
				history.push('/dashboard');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors)
			});
	}
    
    if (isStoreOpen()) return <Redirect to="/shift/operation/current" />;
    return (
        <React.Fragment>
            <div className="mx-auto mt-50 w-1/4">
                <div className="widget card">
                    <div className="card-body text-left pt-20">
                        <i className="las la-desktop text-8xl text-primary text-center w-full" />
                        <form className="mt-10" onSubmit={handleFormSubmit}>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="border-none" width="140px"><strong>Outlet</strong></td>
                                        <td className="border-none"><input name="outlet" type="text" className="form-control text-right bg-gray-600" value={storeBranchName} readOnly={true} /></td>
                                    </tr>
                                    <tr>
                                        <td className="border-none"width="140px"><strong>Cashier</strong></td>
                                        <td className="border-none"><input name="start_by" type="text" className="form-control text-right bg-gray-600" value={request.start_by} readOnly={true} /></td>
                                    </tr>
                                    <tr>
                                        <td className="border-none" width="140px"><strong>Starting Cash</strong></td>
                                        <td className="border-none"><Cleave id="opening_cash_balance" name="opening_cash_balance" type="text" className="form-control text-right" value={request.opening_cash_balance} placeholder="e.g. 200.000" onChange={handleInputChange} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}}/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row mt-10 mb-0">
                                <button className="btn btn-primary w-full uppercase" style={{ display: "inline-block" }}>Start Shift</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(ShiftForm);