import React from "react";

const PageFooter = () => {
    return ( 
        <div className="footer footer-border">
            2021 &copy; FSR - FindProject.net
        </div>
    );
}
 
export default PageFooter;