import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchShift, changeShift } from "../../thunks/shiftsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.shiftDetails);
const shiftDetailsSliceDatasource = createSlice({
	name: "datasources/shiftDetails",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (shiftDetails, _) => {
			dataAdapter.removeAll(shiftDetails, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchShift.fulfilled, (shiftDetails, action) => {
                if (action.payload.hasOwnProperty("shiftDetails")) {
                    dataAdapter.upsertMany(shiftDetails, action.payload.shiftDetails);
                }
			})
			.addCase(changeShift.fulfilled, (shiftDetails, action) => {
				if (action.payload.hasOwnProperty("shiftDetails")) {
					dataAdapter.upsertMany(shiftDetails, action.payload.shiftDetails);
				}
			});
	}
});
export default shiftDetailsSliceDatasource.reducer;

export const unloadShiftDetails = () => shiftDetailsSliceDatasource.actions.unloaded();
export const getShiftDetailEntities = dataView.selectEntities;