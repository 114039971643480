import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/menuEntity";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchMenus = createAsyncThunk(
	"datasources/menus/fetchMenus",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/menus/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.menus);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The menu could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);