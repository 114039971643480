import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchSalesOrder, cancelItem } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.salesOrderDetails);
const salesOrderDetailsSliceDatasource = createSlice({
	name: "datasources/salesOrderDetails",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (salesOrderDetails, _) => {
			dataAdapter.removeAll(salesOrderDetails, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesOrder.fulfilled, (salesOrderDetails, action) => {
                if (action.payload.hasOwnProperty("salesOrderDetails")) {
                    dataAdapter.upsertMany(salesOrderDetails, action.payload.salesOrderDetails);
                }
			})
			.addCase(cancelItem.fulfilled, (salesOrderDetails, action) => {
                if (action.payload.hasOwnProperty("salesOrderDetails")) {
                    dataAdapter.upsertMany(salesOrderDetails, action.payload.salesOrderDetails);
                }
			});
	}
});
export default salesOrderDetailsSliceDatasource.reducer;

export const unloadSalesOrderDetails = () => salesOrderDetailsSliceDatasource.actions.unloaded();
export const getSalesOrderDetailEntities = dataView.selectEntities;