import React from "react";
import Cleave from 'cleave.js/react';
import { Dialog, Transition } from "@headlessui/react";

const FormPaymentConfirmation = ({showDialog, showProcess, outstanding, totalPayment, change, payments, paymentDetails, handleRemovePayment, handleSavePayment, handleCloseDialog}) => {
    
    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-2/5">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Payment Confirmation</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group h-300">
                                        <table className="table text-14">
                                            <thead>
                                                <tr>
                                                    <th width="25%">Payment Method</th>
                                                    <th>Note</th>
                                                    <th width="25%" className="text-right">Payment Amount</th>
                                                    <th width="1%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentDetails.map((paymentDetail, index) => 
                                                    <tr key={index}>
                                                        <td>{payments[paymentDetail.payment_id].name}</td>
                                                        <td>{paymentDetail.note}</td>
                                                        <td className="text-right">{Number(paymentDetail.amount).toLocaleString()}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger w-full" onClick={() => handleRemovePayment(index)}>
                                                                <i className="fas fa-times" style={{margin: "auto"}} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-group">
                                        <div className="flex">
                                            <div className="w-1/3 mr-10">
                                                <label className="form-label">Outstanding</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={outstanding} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/3 mr-10">
                                                <label className="form-label">Total Payment</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={totalPayment} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="form-label">Change</label>
                                                <Cleave className="form-control form-control-lg text-right" disabled={true} value={change} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Close&nbsp;&nbsp;
                                    </button>
                                    {showProcess && 
                                        <React.Fragment>
                                            &nbsp;
                                            <button className="btn btn-primary uppercase" onClick={handleSavePayment}>
                                                &nbsp;&nbsp;Process&nbsp;&nbsp;
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormPaymentConfirmation;