import Authentication from "../resources/templates/layouts/authentication";
import Unauthorized from "../resources/templates/layouts/unauthorized";
import Main from "../resources/templates/layouts/main";
import Pos from "../resources/templates/layouts/pos";

import ForgotPassword from "../resources/templates/vendors/forgotPassword";
import Login from "../resources/templates/vendors/login";
import Stores from "../resources/templates/stores/index";
import DiningTableMap from "../resources/templates/dashboard/diningTableMap";
import Dashboard from "../resources/templates/dashboard";
import Shifts from "../resources/templates/shifts";
import ShiftDetail from "../resources/templates/shifts/detail";
import ShiftForm from "../resources/templates/shifts/form";
import ShiftOperation from "../resources/templates/shifts/operation";
import FormSalesOrder from "../resources/templates/salesOrders/form";
import FormPayment from "../resources/templates/salesOrders/payment";
import SalesInvoices from "../resources/templates/salesInvoices";

export const routes = [
	{ path: "/login", layout: Authentication, component: Login },
	{ path: "/forgot-password", layout: Authentication, component: ForgotPassword },
	{ path: "/my-stores", layout: Authentication, component: Stores },

	{ path: "/home", layout: Unauthorized, component: DiningTableMap, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: false, shiftRequired: false }, 
	{ path: "/dashboard", layout: Pos, component: Dashboard, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: true },
	
	{ path: "/shift/start-day", layout: Main, component: ShiftForm, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: false },
	{ path: "/shift/operation/:id", layout: Main, component: ShiftOperation, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: true },
	{ path: "/shift/logs/detail/:id", layout: Main, component: ShiftDetail, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: false },
	{ path: "/shift/logs", layout: Main, component: Shifts, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: false },
	
	{ path: "/order/:salesOrderCode", layout: Pos, component: FormSalesOrder, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: true },
	{ path: "/payment/:salesOrderCode", layout: Pos, component: FormPayment, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: true },
	{ path: "/sales-invoices", layout: Pos, component: SalesInvoices, authorizedRequired: true, selectedStoreRequired: true, waiterAuthorizedRequired: true, shiftRequired: false },
];