import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchMenus } from "../../thunks/menusThunk";
import { fetchSalesOrder } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.menus);
const menusSliceDatasource = createSlice({
	name: "datasources/menus",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menus, _) => {
			dataAdapter.removeAll(menus, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenus.fulfilled, (menus, action) => {
				if (action.payload.hasOwnProperty("menus")) {
					dataAdapter.setAll(menus, action.payload.menus);
				}
			})
			
			.addCase(fetchSalesOrder.fulfilled, (menu, action) => {
				if (action.payload.hasOwnProperty("menu")) {
					dataAdapter.setAll(menu, action.payload.menu);
				}
			});
	}
});
export default menusSliceDatasource.reducer;

export const unloadMenus = () => menusSliceDatasource.actions.unloaded();
export const getMenus = createSelector(
	state => state.datasources.menuCategories.ids,
	dataView.selectAll,
	(menuCategories, menus) => {
		return menuCategories
		.reduce((menuCategory, id) => { 
			return {
				...menuCategory, 
				[id]: menus.filter(menu => menu.menu_category_id === id)
			} 
		}, {});
	}
	);
export const getMenuEntites = dataView.selectEntities;
export const getMenuPrices = createSelector(
	dataView.selectIds,
	state => state.datasources.menuStoreBranches.ids.map(id => state.datasources.menuStoreBranches.entities[id]),
	(menus, menuStoreBranches) => {
		return menus
			.reduce((menu, id) => {
				const menuStoreBranch = menuStoreBranches.find(menuStoreBranch => menuStoreBranch.menu_id === id);
				return {
					...menu,
					[id]: menuStoreBranch ? menuStoreBranch.price : 0
				}
			}, {})
	}
);