import { configure } from "../../config/app";
import { authorize } from "../thunks/vendorsThunk";
import { signIn } from "../thunks/waitersThunk";
import { fetchCurrentShift, startDay, endDay } from "../thunks/shiftsThunk";
import { selectedStore } from "../slices/authentications/storeSliceAuthentication";
import { signedOut } from "../slices/authentications/waiterSliceAuthentication";
import { loggedout } from "../slices/authentications/vendorSliceAuthentication";

const localStorageMiddleware = store => next => action => {
	if (action.type === authorize.fulfilled.type) {
		localStorage.setItem(configure.StorageKey.Vendor, JSON.stringify(action.payload.vendor));
	}

	if (action.type === selectedStore.type) {
		localStorage.setItem(configure.StorageKey.CurrentStore, JSON.stringify(action.payload.store));
		localStorage.setItem(configure.StorageKey.CurrentStoreBranch, JSON.stringify(action.payload.storeBranch));
	}
	
	if (action.type === signIn.fulfilled.type) {
		sessionStorage.setItem(configure.StorageKey.Waiter, JSON.stringify(action.payload.waiter));
	}

	if (action.type === signedOut.type) {
		sessionStorage.removeItem(configure.StorageKey.Waiter);
	}

	if ([fetchCurrentShift.fulfilled.type, startDay.fulfilled.type, endDay.fulfilled.type].indexOf(action.type) > -1) {
		localStorage.setItem(configure.StorageKey.IsStoreOpen, action.payload.shift.is_closed === 0 ? 1 : 0);
	}

	if (action.type === loggedout.type) {
		localStorage.removeItem(configure.StorageKey.Vendor);
		localStorage.removeItem(configure.StorageKey.CurrentStore);
		localStorage.removeItem(configure.StorageKey.CurrentStoreBranch);
		sessionStorage.removeItem(configure.StorageKey.Waiter);
		localStorage.removeItem(configure.StorageKey.IsStoreOpen);
	}

	next(action);
}
export default localStorageMiddleware;