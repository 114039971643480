import { useHistory } from "react-router-dom";
import moment from "moment";

const DataTable = ({ shifts }) => {
    const history = useHistory();

    const handleSelectShift = shiftId => {
        return history.push(`/shift/logs/detail/${shiftId}`);;
    }

    return (
        <table className="table table-hover text-14">
            <thead>
                <tr>
                    <th className="text-left" width="10%">Starting Shift</th>
                    <th className="text-left">Started By</th>
                    <th className="text-right">Starting Cash&nbsp;&nbsp;</th>
                    <th className="text-left" width="10%">Ending Shift</th>
                    <th className="text-left">Ended By</th>
                    <th className="text-right">Expected Cash</th>
                    <th className="text-right">Actual Cash</th>
                    <th className="text-right">Difference Cash</th>
                </tr>
            </thead>
            <tbody>
                {shifts.map(shift => 
                
					<tr key={shift.id} className="cursor-pointer" onClick={() => handleSelectShift(shift.id)}>
						<td>{moment(shift.from_date).format("DD-MM-YYYY HH:mm")}</td>
						<td>{shift.start_by}</td>
						<td className="text-right">{Number(shift.opening_cash_balance).toLocaleString()}&nbsp;&nbsp;</td>
						<td>{shift.is_closed === 0 ? <strong className="text-yellow-600">- Currently Open -</strong> : moment(shift.to_date).format("DD-MM-YYYY HH:mm")}</td>
						<td>{shift.is_closed === 0 ? "" : shift.end_by}</td>
						<td className="text-right">{shift.is_closed === 0 ? "" : Number(shift.expected_cash_balance).toLocaleString()}</td>
						<td className="text-right">{shift.is_closed === 0 ? "" : Number(shift.actual_cash_balance).toLocaleString()}</td>
						<td className="text-right">{shift.is_closed === 0 ? "" : Number(Number(shift.expected_cash_balance) - Number(shift.actual_cash_balance)).toLocaleString()}</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;