const TableInformation = () => {
    return (
        <div className="flex flex-grow justify-around mx-5 my-5">
            <div className="flex align-middle">
                <div className="btn btn-primary w-24 h-24 mr-10">&nbsp;</div>
                <span>Available</span>
            </div>
            <div className="flex align-middle">
                <div className="btn btn-success w-24 h-24 mr-10">&nbsp;</div>
                <span>Occupied</span>
            </div>
        </div>
    );
}
 
export default TableInformation;