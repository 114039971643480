import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const FormTax = ({ showDialog, serviceChargeExempt, vatExempt, handleUpdateTax, handleCloseDialog }) => {

    const [request, setRequest] = React.useState({ serviceChargeExempt: false, vatExempt: false });

    React.useEffect(() => {
        setRequest({ serviceChargeExempt, vatExempt });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog]);

    const handleInputChange = element => {
        const { name, checked } = element.target;
		setRequest({...request, [name]: checked ? 1 : 0});
    }

    const handleApplyUpdate = () => {
        handleUpdateTax(request.serviceChargeExempt, request.vatExempt);
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-1/4">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Tax Exempt</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group text-center my-30">
                                        <div className="flex">
                                            <div className="w-1/2 mr-5">
                                                <input type="checkbox" className="btn-check" id="serviceChargeExempt" name="serviceChargeExempt" value={1} onChange={handleInputChange} defaultChecked={request.serviceChargeExempt} />
                                                <label className="btn btn-outline-primary w-full h-70" htmlFor="serviceChargeExempt">Service Charge</label>
                                            </div>
                                            <div className="w-1/2 ml-5">
                                                <input type="checkbox" className="btn-check" id="vatExempt" name="vatExempt" value={1} onChange={handleInputChange} defaultChecked={request.vatExempt} />
                                                <label className="btn btn-outline-primary w-full h-70" htmlFor="vatExempt">VAT</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary uppercase" onClick={handleApplyUpdate}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormTax;