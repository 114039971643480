import React from "react";
import _ from "lodash";

const NumberControl = ({ perPage, maxValue, handleClick }) => {

    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemCount, setItemCount] = React.useState(perPage);

    const handlePrevPage = () => {
        setCurrentPage(itemCount - perPage <= 0 ? 1 : currentPage - perPage);
        setItemCount(itemCount - perPage <= 0 ? perPage : itemCount - perPage);
    }

    const handleNextPage = () => {
        if (itemCount >= maxValue) {
            return;
        }

        setCurrentPage(currentPage + 5);
        setItemCount(itemCount + 5);
    }

    return (
        <React.Fragment>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                <button type="button" className="btn btn-outline-secondary text-16 mr-5" style={{padding: "15px 25px"}} onClick={handlePrevPage}>
                    &laquo;
                </button>
                
                {_.range(currentPage, itemCount + 1).map(page => (
                    <button key={page} type="button" className="btn btn-outline-secondary mr-5 w-60" style={{display: "block", maxWidth : "60px"}} onClick={() => handleClick(page)}>
                        {page}
                    </button>
                ))}
                
                <button type="button" className="btn btn-outline-secondary text-16 mr-5" style={{padding: "15px 25px"}} onClick={handleNextPage}>
                    &raquo;
                </button>
            </nav>
        </React.Fragment>
    );
}
 
export default NumberControl;