import { createSlice } from "@reduxjs/toolkit";
import { configure } from "../../../config/app";
import { selectedStore } from "./storeSliceAuthentication";

const defaultValue = { id: "", code: "", name: "", address_line_1: "", address_line_2: "", phone: "" };
const initialState = localStorage.getItem(configure.StorageKey.CurrentStoreBranch) === null ? defaultValue : JSON.parse(localStorage.getItem(configure.StorageKey.CurrentStoreBranch));
const storeBranchSliceAuthentication = createSlice({
	name: "auth/storeBranch",
	initialState: initialState,
	extraReducers: builder => {
		builder
			.addCase(selectedStore, (storeBranch, action) => {
				storeBranch.id = action.payload.storeBranch.id;
				storeBranch.code = action.payload.storeBranch.code;
				storeBranch.name = action.payload.storeBranch.name;
				storeBranch.address_line_1 = action.payload.storeBranch.address_line_1; 
				storeBranch.address_line_2 = action.payload.storeBranch.address_line_2; 
				storeBranch.phone = action.payload.storeBranch.phone; 
			}) 
	}
});
export default storeBranchSliceAuthentication.reducer;