import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "menuCategories" : "menuCategory";
	const stationEntity = new schema.Entity("station");
	const menusEntity = new schema.Entity("menus");
	const menuCategoryEntity = new schema.Entity(
		dataset,
		{
			menus: [menusEntity],
			station: stationEntity
		}
	);
	const result = normalize(
		datasources, 
		isArray ? [menuCategoryEntity] : menuCategoryEntity
	);

	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.menuCategories;
	const dataset = isArray ? "menuCategories" : "menuCategory";
	const menuCategoryEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.menuCategories : datasources.menuCategory;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [menuCategoryEntity] : menuCategoryEntity }, 
		{ [dataset]: entities }
	);
}