import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchSalesInvoices, fetchSalesInvoice } from "../../thunks/salesInvoicesThunk";
import { fetchDiningTableSections, fetchDiningTableSection } from "../../thunks/diningTableSectionsThunk";
import { fetchSalesOrder } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.diningTables);
const diningTablesSliceDatasource = createSlice({
	name: "datasources/diningTables",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (diningTables, _) => {
			dataAdapter.removeAll(diningTables, {});
		}
	},
	extraReducers: builder => {
		builder
			.addMatcher(isAnyOf(fetchDiningTableSections.fulfilled, fetchDiningTableSection.fulfilled), (diningTables, action) => {
				if (action.payload.hasOwnProperty("diningTables")) {
					dataAdapter.setAll(diningTables, action.payload.diningTables);
				}
			})
			.addMatcher(isAnyOf(fetchSalesOrder.fulfilled), (diningTable, action) => {
				if (action.payload.hasOwnProperty("diningTable")) {
					dataAdapter.setAll(diningTable, action.payload.diningTable);
				}
			})
			.addMatcher(isAnyOf(fetchSalesInvoices.fulfilled, fetchSalesInvoice.fulfilled), (diningTable, action) => {
				if (action.payload.hasOwnProperty("diningTable")) {
					dataAdapter.setAll(diningTable, action.payload.diningTable);
				}
			});
	}
});
export default diningTablesSliceDatasource.reducer;

export const unloadDiningTables = () => diningTablesSliceDatasource.actions.unloaded();
export const getDiningTableEntities = dataView.selectEntities;