import { createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchCurrentShift } from "../../thunks/shiftsThunk";

const defaultValue = { 
	currentShift: {}
};
const currentShiftSliceCommon = createSlice({
	name: "common/currentShift",
	initialState: defaultValue,
	extraReducers: builder => {
		builder
			.addCase(fetchCurrentShift.fulfilled, (currentShift, action) => {
				if (action.payload.shift) {
					currentShift.id = action.payload.shift.id;
					currentShift.shift_id = action.payload.shift.shift_id;
					currentShift.start_by = action.payload.shift.start_by;
					currentShift.from_date = action.payload.shift.from_date;
					currentShift.end_by = action.payload.shift.end_by;
					currentShift.to_date = action.payload.shift.to_date;
					currentShift.opening_balance = action.payload.shift.opening_balance;
					currentShift.closing_balance = action.payload.shift.closing_balance;
					currentShift.is_closed = action.payload.shift.is_closed;
				}
			})
	}
});
export default currentShiftSliceCommon.reducer;

export const unloadShifts = () => currentShiftSliceCommon.actions.unloaded();
export const getCurrentShift = createSelector(
	state => state.common.currentShift,
	currentShift => currentShift
);