import React from "react";
import { paginate } from "../../../helpers/paginate";
import SmallPaginator from "../../elements/smallPaginator";

const MenuCategories = ({ menuCategories, handleSelectedMenuCategory }) => {
	
	const [currentPage, setCurrentPage] = React.useState(1);
	const [pageSize] = React.useState(16);
	const pagesCount = Math.ceil(menuCategories.length / pageSize);

	const getPagedMenuCategories = () => {
		return paginate(menuCategories, currentPage, pageSize);
	}

	const handleOnPrev = () => {
		setCurrentPage(currentPage - 1 <= 0 ? 1 : currentPage - 1);
	}
	
	const handleOnNext = () => {
		setCurrentPage(currentPage + 1 > pagesCount ? pagesCount : currentPage + 1);
	}

	return (
		<div className="classic card h-full">
			<div className="card-header bg-gray-100 h-65">
				<div className="flex">
					<h3 className="flex card-title items-center h-40">
						Menu Category 
					</h3>
					<div className="ml-auto">
						<SmallPaginator	
							itemsCount={menuCategories.length} 
							pageSize={pageSize}
							currentPage={currentPage}
							handleOnPrev={handleOnPrev}
							handleOnNext={handleOnNext}
						/>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="h-full grid grid-cols-4 grid-rows-4 gap-10">
					{getPagedMenuCategories().map(menuCategory => 
						<button key={menuCategory.id} className="btn btn-primary text-14" style={{display: "block"}} onClick={() => handleSelectedMenuCategory(menuCategory)}>
							{menuCategory.name}
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
 
export default MenuCategories;