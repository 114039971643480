import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchPayments } from "../../thunks/paymentsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.payments);
const paymentsSliceDatasource = createSlice({
	name: "datasources/payments",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (payments, _) => {
			dataAdapter.removeAll(payments, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPayments.fulfilled, (payments, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					payments.pagination = action.payload.pagination;
				}
	
				if (action.payload.hasOwnProperty("payments")) {
					dataAdapter.setAll(payments, action.payload.payments);
				}
			});
	}
});
export default paymentsSliceDatasource.reducer;

export const unloadPayments = () => paymentsSliceDatasource.actions.unloaded();
export const getPayments = createSelector(
	state => state.datasources.paymentCategories.ids,
	dataView.selectAll,
	(paymentCategories, payments) => {
		return paymentCategories
		.reduce((paymentCategory, id) => { 
			return {
				...paymentCategory, 
				[id]: payments.filter(payment => payment.payment_category_id === id)
			} 
		}, {});
	}
);
export const getPaymentEntities = dataView.selectEntities;