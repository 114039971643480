import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchDiningTableSections, fetchDiningTableSection } from "../../thunks/diningTableSectionsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.diningTableSections);
const diningTableSectionsSliceDatasource = createSlice({
	name: "datasources/diningTableSections",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (diningTableSections, _) => {
			dataAdapter.removeAll(diningTableSections, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDiningTableSections.fulfilled, (diningTableSections, action) => {
				if (action.payload.hasOwnProperty("diningTableSections")) {
					dataAdapter.setAll(diningTableSections, action.payload.diningTableSections);
				}
			})
			.addCase(fetchDiningTableSection.fulfilled, (diningTableSection, action) => {
				if (action.payload.hasOwnProperty("diningTableSection")) {
					dataAdapter.upsertMany(diningTableSection, action.payload.diningTableSection);
				}
			});
	}
});
export default diningTableSectionsSliceDatasource.reducer;

export const unloadDiningTableSections = () => diningTableSectionsSliceDatasource.actions.unloaded();
export const getDiningTableSections = dataView.selectAll;
export const getDiningTables = diningTableSectionId => createSelector(
	state => dataView.selectById(state, diningTableSectionId),
	state => state.datasources.diningTables.ids.map(id => state.datasources.diningTables.entities[id]),
	(diningTableSection, diningTables) => {
		if (diningTableSection) {
			return Object.keys(diningTables)
				.map(key => diningTables[key])
				.filter(diningTable => diningTableSection.dining_tables.includes(diningTable.id));
		}

		return [];
	}
);