import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const signIn = createAsyncThunk(
	"auth/waiters/signIn",
	async (request, thunkAPI) => {
		try {
			const formData = {pin: request};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/waiters/sign-in", formData, fsrHttpConfiguration()));
			
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The waiter could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);