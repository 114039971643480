import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/shiftEntity";
import { setFlashError } from "../slices/commons/flashSliceCommon";
import { configure } from "../../config/app";

export const fetchShifts = createAsyncThunk(
	"datasources/shifts/fetchShifts",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/shifts/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.shifts);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}  
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchShift = createAsyncThunk(
	"datasources/shifts/fetchShift",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/shifts/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.shift);

			return {
				...normalized
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchCurrentShift = createAsyncThunk(
	"common/shifts/fetchCurrentShift",
	async (_, thunkAPI) => {
		try {
			const { id } = JSON.parse(localStorage.getItem(configure.StorageKey.CurrentStoreBranch));
			const params = { storeBranchId: id, current: true };
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/shifts/view", {params, ...fsrHttpConfiguration()}));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchCashierShiftReport = createAsyncThunk(
	"common/shifts/fetchCashierShiftReport",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/shifts/print-shift-report", {...params, ...fsrHttpConfiguration()}));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift report could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const startDay = createAsyncThunk(
	"common/shifts/startDay",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/shifts/start-day", formData, fsrHttpConfiguration()));
			
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const changeShift = createAsyncThunk(
	"datasources/shifts/changeShift",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/shifts/change", formData, fsrHttpConfiguration()));
			const normalized = normalizeEntity(response.data.shift);
			
			return {
				...normalized,
				shift_report: response.data.shift_report || {}  
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const endDay = createAsyncThunk(
	"common/shifts/endDay",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/shifts/end-day", formData, fsrHttpConfiguration()));
			const normalized = normalizeEntity(response.data.shift);

			return {
				...normalized,
				shift_report: response.data.shift_report || {}  
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The shift could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);