import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchSalesInvoices, fetchSalesInvoice } from "../../thunks/salesInvoicesThunk";
import { fetchSalesOrders, fetchSalesOrder, dineIn, cancelItem } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.salesOrders);
const salesOrdersSliceDatasource = createSlice({
	name: "datasources/salesOrders",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (salesOrders, _) => {
			dataAdapter.removeAll(salesOrders, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesOrders.fulfilled, (salesOrders, action) => {
				if (action.payload.hasOwnProperty("salesOrders")) {
					dataAdapter.setAll(salesOrders, action.payload.salesOrders);
				}
			})
			.addCase(fetchSalesOrder.fulfilled, (salesOrder, action) => {
				if (action.payload.hasOwnProperty("salesOrder")) {
					dataAdapter.upsertMany(salesOrder, action.payload.salesOrder);
				}
			})
			.addCase(dineIn.fulfilled, (salesOrder, action) => {
				dataAdapter.addOne(salesOrder, action.payload.salesOrder);
			})
			.addCase(cancelItem.fulfilled, (salesOrder, action) => {
				dataAdapter.upsertMany(salesOrder, action.payload.salesOrder);
			})
			.addMatcher(isAnyOf(fetchSalesInvoices.fulfilled, fetchSalesInvoice.fulfilled), (salesOrder, action) => {
				if (action.payload.hasOwnProperty("salesOrder")) {
					dataAdapter.setAll(salesOrder, action.payload.salesOrder);
				}
			});
	}
});
export default salesOrdersSliceDatasource.reducer;

export const unloadSalesOrders = () => salesOrdersSliceDatasource.actions.unloaded();
export const getSalesOrderEntities = dataView.selectEntities;
export const getDineInSalesOrders = createSelector(
	dataView.selectAll,
	salesOrders => {
		return salesOrders
			.reduce((salesOrder, entity) => { 
				return {
					...salesOrder, 
					[entity.dining_table_id]: entity
				} 
		}, {});
	}
);
export const getSalesOrderByCode = code => createSelector(
	dataView.selectAll,
	salesOrders => salesOrders.find(salesOrder => salesOrder.code === code)
);