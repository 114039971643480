import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMenuCategories } from "../../thunks/menuCategoriesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.menuCategories);
const menuCategoriesSliceDatasource = createSlice({
	name: "datasources/menuCategories",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menuCategories, _) => {
			dataAdapter.removeAll(menuCategories, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenuCategories.fulfilled, (menuCategories, action) => {
				if (action.payload.hasOwnProperty("menuCategories")) {
					dataAdapter.setAll(menuCategories, action.payload.menuCategories);
				}
			});
	}
});
export default menuCategoriesSliceDatasource.reducer;

export const unloadMenuCategories = () => menuCategoriesSliceDatasource.actions.unloaded();
export const getMenuCategories = dataView.selectAll;