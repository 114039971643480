import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import Cleave from "cleave.js/react";

const PaymentTypeOther = ({ showDialog, subTotal, handleAddPayment, handleCloseDialog }) => {

    const [amount, setAmount] = React.useState(subTotal);
    const [note, setNote] = React.useState("");

    const handleApplyPayment = () => {
        handleAddPayment("", "", "", amount, note);
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-2/5">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Other Payment Type</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <div className="flex">
                                            <div className="w-1/2 mr-20">
                                                <label className="form-label" htmlFor="outstanding">Outstanding</label>
                                                <Cleave id="outstanding" name="outstanding" type="text" className="form-control form-control-lg text-right" value={subTotal} disabled={true} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                            <div className="w-1/2 ml-2">
                                                <label className="form-label" htmlFor="amount">Sub Total</label>
                                                <Cleave id="amount" name="amount" type="text" className="form-control form-control-lg text-right" value={amount} onChange={e => setAmount(e.target.rawValue)} readOnly={true} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="note">Note</label>
                                        <textarea id="note" name="note" type="text" className="form-control form-control-lg" placeholder="Note" value={note} onChange={e => setNote(e.target.value)} rows="4" resize="none" />
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary uppercase" onClick={handleApplyPayment}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default PaymentTypeOther;