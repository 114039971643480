import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchSalesInvoices, fetchSalesInvoice } from "../../thunks/salesInvoicesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.salesInvoices);
const salesInvoicesSliceDatasource = createSlice({
	name: "datasources/salesInvoices",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (salesInvoices, _) => {
			dataAdapter.removeAll(salesInvoices, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesInvoices.fulfilled, (salesInvoices, action) => {
				if (action.payload.hasOwnProperty("salesInvoices")) {
					dataAdapter.setAll(salesInvoices, action.payload.salesInvoices);
				}
			})
			.addCase(fetchSalesInvoice.fulfilled, (salesInvoice, action) => {
				if (action.payload.hasOwnProperty("salesInvoice")) {
					dataAdapter.upsertMany(salesInvoice, action.payload.salesInvoice);
				}
			});
	}
});
export default salesInvoicesSliceDatasource.reducer;

export const unloadSalesInvoices = () => salesInvoicesSliceDatasource.actions.unloaded();
export const getSalesInvoices = dataView.selectAll;
export const getSalesInvoiceEntities = dataView.selectEntities;
export const getSalesInvoicePagination = createSelector(
	state => state.datasources.salesInvoices.pagination,
	pagination => pagination
);
export const getSalesInvoice = id => createSelector(
	state => state.datasources.salesInvoices.entities,
	salesInvoices => salesInvoices[id]
);