import moment from "moment";

export const printStationOrders = stationsOrders => {
    stationsOrders.forEach(stationsOrder => {
        const builder = new window.epson.ePOSBuilder();

        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addTextSize(1, 2);
        builder.addText(`${stationsOrder.station.name} Order Ticket\n`);
        builder.addText(`Table : ${stationsOrder.dining_table === 0 ? 'Take Away' : stationsOrder.dining_table}\n`);
        builder.addTextSize(1, 1);
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("==========================================\n");
        builder.addText(` ORDER     : ${stationsOrder.code}\n`);
        builder.addText(` DATE      : ${moment(stationsOrder.order_date).format("DD-MM-YYYY HH:mm:ss")}\n`);
        builder.addText(` WAITER    : ${stationsOrder.waiter}\n`);
        builder.addText("==========================================\n");
        builder.addTextSize(1, 2);

        stationsOrder.items.forEach(item => {
            builder.addText(` ${Number(item.quantity).toLocaleString()} ${item.name}\n`);
            if (item.note !== "") {
                builder.addText(`   * ${item.note}\n`);
            }
        });

        builder.addTextSize(1, 1);
        builder.addText("------------------------------------------\n");
        builder.addText("------------------------------------------\n");
        builder.addFeedLine(2);
        builder.addCut(builder.CUT_FEED);

        print(stationsOrder.station.printer, builder.toString());
    });
}

export const printCancelledItem = cancelledItem => {
    const builder = new window.epson.ePOSBuilder();

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addTextSize(1, 2);
    builder.addText(`CANCELLED ITEM\n`);
    builder.addText(`Table : ${cancelledItem.dining_table === 0 ? 'Take Away' : cancelledItem.dining_table}\n`);
    builder.addTextSize(1, 1);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` ORDER     : ${cancelledItem.code}\n`);
    builder.addText(` DATE      : ${moment(cancelledItem.order_date).format("DD-MM-YYYY HH:mm:ss")}\n`);
    builder.addText(` WAITER    : ${cancelledItem.waiter}\n`);
    builder.addText("==========================================\n");
    builder.addTextSize(1, 2);

    cancelledItem.items.forEach(item => {
        builder.addText(` ${Number(item.quantity).toLocaleString()} ${item.name}\n`);
        if (item.note !== "") {
            builder.addText(`   * ${item.note}\n`);
        }
    });

    builder.addTextSize(1, 1);
    builder.addText("------------------------------------------\n");
    builder.addText("------------------------------------------\n");
    builder.addFeedLine(2);
    builder.addCut(builder.CUT_FEED);

    print(cancelledItem.station.printer, builder.toString());
}

export const printPresettlementBill = presettlement => {
    const builder = new window.epson.ePOSBuilder();
    
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`${presettlement.store_branch.name}\n`);
    builder.addText(`${presettlement.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    builder.addText(` ORDER     : ${presettlement.code}\n`);
    builder.addText(` DATE      : ${moment(presettlement.order_date).format("DD-MM-YYYY")}\n`);
    builder.addText(` Table     : ${presettlement.dining_table === 0 ? 'Take Away' : presettlement.dining_table}\n`);
    builder.addText(` PAX       : ${Number(presettlement.pax).toLocaleString()}\n`);
    builder.addText(` WAITER    : ${presettlement.waiter}\n`);
    builder.addText("------------------------------------------\n");
    
    presettlement.items.forEach(item => {
        const quantity = Number(item.quantity).toLocaleString().padEnd(3, " ");
        const itemName = item.name.padEnd(25, " ");
        const total = Number(item.total).toLocaleString().padStart(10, " ");

        builder.addText(` ${quantity} ${itemName} ${total}\n`);
        if (parseInt(item.total_discount_percent) > 0) {
            builder.addText(`${"".padEnd(4, " ")} ${"Discount (" + Number(item.total_discount_percent).toLocaleString() + "%)".padEnd(25, " ")} ${Number(item.total_discount_percent_value * -1).toLocaleString().padStart(10, " ")}\n`);
        }
        if (parseInt(item.total_discount_value) > 0) {
            builder.addText(`${"".padEnd(4, " ")} ${"Discount".padEnd(25, " ")} ${Number(item.total_discount_value * -1).toLocaleString().padStart(10, " ")}\n`);
        }
    });

    builder.addText("------------------------------------------\n");
    builder.addText(`${"Subtotal : ".padStart(30)} ${Number(presettlement.sub_total).toLocaleString().padStart(10)}\n`);
    builder.addText(`${"SC : ".padStart(30)} ${Number(presettlement.service_charge).toLocaleString().padStart(10)}\n`);
    builder.addText(`${"TAX : ".padStart(30)} ${Number(presettlement.tax).toLocaleString().padStart(10)}\n`);
    builder.addText("          --------------------------------\n");
    builder.addText(`${"Grand Total : ".padStart(30)} ${Number(presettlement.grand_total).toLocaleString().padStart(10)}\n`);
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addFeed();
    builder.addText("------------------------------------------\n");
    builder.addText("- Presettlement Bill -\n");
    builder.addFeedLine(3);
    builder.addCut(builder.CUT_FEED);

    print(presettlement.station.printer, builder.toString());
}

export const printBill = bill => {
    const builder = new window.epson.ePOSBuilder();
    
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`${bill.store_branch.name}\n`);
    builder.addText(`${bill.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    if (bill.number_of_print_invoice > 1) {
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addTextSize(2, 2);
        builder.addText(`Reprint: ${bill.number_of_print_invoice}\n`);
        builder.addTextSize(1, 1);
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n");

    }
    builder.addText(` ORDER     : ${bill.code}\n`);
    builder.addText(` DATE      : ${moment(bill.invoice_date).format("DD-MM-YYYY")}\n`);
    builder.addText(` Table     : ${bill.dining_table}\n`);
    builder.addText(` PAX       : ${Number(bill.pax).toLocaleString()}\n`);
    builder.addText(` CASHIER   : ${bill.cashier}\n`);
    builder.addText("------------------------------------------\n");
    
    bill.items.forEach(item => {
        const quantity = Number(item.quantity).toLocaleString().padEnd(3, " ");
        const itemName = item.name.padEnd(25, " ");
        const total = Number(item.total).toLocaleString().padStart(10, " ");

        builder.addText(` ${quantity} ${itemName} ${total}\n`);
        if (parseInt(item.total_discount_percent) > 0) {
            builder.addText(`${"".padEnd(4, " ")} ${"Discount (" + Number(item.total_discount_percent).toLocaleString() + "%)".padEnd(25, " ")} ${Number(item.total_discount_percent_value * -1).toLocaleString().padStart(10, " ")}\n`);
        }
        if (parseInt(item.total_discount_value) > 0) {
            builder.addText(`${"".padEnd(4, " ")} ${"Discount".padEnd(25, " ")} ${Number(item.total_discount_value * -1).toLocaleString().padStart(10, " ")}\n`);
        }
    });

    builder.addText("------------------------------------------\n");
    builder.addText(`${"Subtotal : ".padStart(30)} ${Number(bill.sub_total).toLocaleString().padStart(10)}\n`);
    builder.addText(`${"SC : ".padStart(30)} ${Number(bill.service_charge_percent_value).toLocaleString().padStart(10)}\n`);
    builder.addText(`${"TAX : ".padStart(30)} ${Number(bill.vat_percent_value).toLocaleString().padStart(10)}\n`);
    builder.addText("                --------------------------\n");
    builder.addText(`${"Grand Total : ".padStart(30)} ${Number(bill.grand_total).toLocaleString().padStart(10)}\n`);
    bill.payments.forEach(payment => {
        builder.addText(`${payment.name.padStart(27)} : ${Number(payment.amount).toLocaleString().padStart(11)}\n`);
    });
    if (bill.change > 0)  {
        builder.addText(`${"Change : ".padStart(30)} ${Number(bill.change).toLocaleString().padStart(10)}\n`);
    }

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addFeed();
    builder.addText("------------------------------------------\n");
    builder.addText("- Thank You -\n");
    builder.addFeedLine(3);
    builder.addCut(builder.CUT_FEED);

    print(bill.station.printer, builder.toString());
}

export const printChangeOfShift = datasource => {
    const builder = new window.epson.ePOSBuilder();
    
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("SHIFT CHANGE REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift_detail.closed_by}\n`);
    builder.addText(` Start Shift Date   :    ${moment(datasource.shift_detail.shift_in).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` End Shift Date     :    ${moment(datasource.shift_detail.shift_out).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Shift Summary | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    builder.addText(` Serving Order\n`);
    builder.addText(`${"  Total Serving Order".padEnd(25)} : ${Number(datasource.serving_order.total_serving_order).toLocaleString().padStart(13)}\n`);
    builder.addText(`${"  Grand Total".padEnd(25)} : ${Number(datasource.serving_order.grand_total).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.sales_total).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Menu Discount".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_menu_discount).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total Bill Discount".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_bill_discount).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Net Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.net_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total Service Charge".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_service_charge).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total VAT".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_vat).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total Rounding".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_rounding).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Gross Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.gross_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Number of Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Net Sales per Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_net_per_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Sales per Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_gross_per_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`\n`);
    builder.addText(`${" Number of Bills".padEnd(25)} : ${Number(datasource.sales_invoice_summary.number_of_bills).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Net Sales per Bill".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_net_per_bill).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Sales per Bill".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_gross_per_bill).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Payment Method Summary | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    datasource.sales_invoice_payments.forEach(salesInvoicePayment => {
        builder.addText(` ${salesInvoicePayment.name}\n`);
        builder.addText(`${"  - Qty".padEnd(25)} : ${Number(salesInvoicePayment.number_of_invoice).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total".padEnd(25)} : ${Number(salesInvoicePayment.total_amount).toLocaleString().padStart(13)}\n`);
    });
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.gross_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addCut(builder.CUT_FEED);

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("END OF SHIFT REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift_detail.closed_by}\n`);
    builder.addText(` Start Shift Date   :    ${moment(datasource.shift_detail.shift_in).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` End Shift Date     :    ${moment(datasource.shift_detail.shift_out).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    datasource.sales_invoice_payments.forEach(salesInvoicePayment => {
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addText(`${salesInvoicePayment.name}\n`);
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("------------------------------------------\n");

        salesInvoicePayment.sales_invoices.forEach(salesInvoice => {
            builder.addText(` ${salesInvoice.code.padEnd(25)} : ${Number(salesInvoice.grand_total).toLocaleString().padStart(12)}\n`);
        });

        builder.addText("------------------------------------------\n");
        builder.addText(` Total ${salesInvoicePayment.name.padEnd(19)} : ${Number(salesInvoicePayment.total_amount).toLocaleString().padStart(12)}\n`);
        builder.addText("==========================================\n");
    });
    builder.addCut(builder.CUT_FEED);
    
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("CASHIER SHIFT REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift_detail.closed_by}\n`);
    builder.addText(` Start Shift Date   :    ${moment(datasource.shift_detail.shift_in).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` End Shift Date     :    ${moment(datasource.shift_detail.shift_out).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Sales By Menu | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    datasource.sales_invoice_menus.forEach(salesInvoiceMenu => {
        builder.addText(` ${salesInvoiceMenu.menu.name}\n`);
        builder.addText(`${"  - Qty".padEnd(25)} : ${Number(salesInvoiceMenu.quantity).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total Discount".padEnd(25)} : ${Number(salesInvoiceMenu.total_discount).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total".padEnd(25)} : ${Number(salesInvoiceMenu.sub_total).toLocaleString().padStart(13)}\n`);
    });
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.net_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addCut(builder.CUT_FEED);

    print(datasource.station.printer, builder.toString());
}

export const printEndOfShift = datasource => {
    const builder = new window.epson.ePOSBuilder();

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("END OF SHIFT REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift.start_by}\n`);
    builder.addText(` Shift In Date      :    ${moment(datasource.shift.from_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Shift Out Date     :    ${moment(datasource.shift.to_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Transaction List | ${moment(datasource.shift.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    datasource.sales_invoices.forEach(salesInvoice => {
        builder.addText(` ${salesInvoice.code.padEnd(25)} : ${Number(salesInvoice.grand_total).toLocaleString().padStart(12)}\n`);
    });
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Number of Bills".padEnd(25)} : ${Number(datasource.sales_invoice_summary.number_of_bills).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total Sales".padEnd(25)} : ${Number(datasource.sales_invoice_summary.gross_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`CLOSING NOTES\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`${moment(datasource.from_date).format("DD-MM-YYYY hh:mm")} - ${moment(datasource.to_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText(` Shift Out By       : ${datasource.shift.end_by}\n`);
    builder.addText(` Opening Cash       :    ${Number(datasource.shift.opening_cash_balance).toLocaleString().padStart(16)}\n`);
    builder.addText(` Received Cash      :    ${Number(datasource.shift.received_cash_payment_balance).toLocaleString().padStart(16)}\n`);
    builder.addText(`${" ".padEnd(21)} -------------------\n`);
    builder.addText(` Expected Cash      :    ${Number(datasource.shift.expected_cash_balance).toLocaleString().padStart(16)}\n`);
    builder.addText(` Actual Cash        :    ${Number(datasource.shift.actual_cash_balance).toLocaleString().padStart(16)}\n`);
    builder.addText(`${" ".padEnd(21)} -------------------\n`);
    builder.addText(` Difference Total   :    ${Number(Number(datasource.shift.expected_cash_balance) - Number(datasource.shift.actual_cash_balance)).toLocaleString().padStart(16)}\n`);
    builder.addText("==========================================\n");
    builder.addCut(builder.CUT_FEED);

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("END OF SHIFT REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift.start_by}\n`);
    builder.addText(` Shift In Date      :    ${moment(datasource.shift.from_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Shift Out Date     :    ${moment(datasource.shift.to_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Shift Summary | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.sales_total).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Menu Discount".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_menu_discount).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total Bill Discount".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_bill_discount).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Net Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.net_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total Service Charge".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_service_charge).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total VAT".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_vat).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Total Rounding".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_rounding).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Gross Sales Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.gross_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Number of Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.total_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Net Sales per Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_net_per_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Sales per Pax".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_gross_per_pax).toLocaleString().padStart(13)}\n`);
    builder.addText(`\n`);
    builder.addText(`${" Number of Bills".padEnd(25)} : ${Number(datasource.sales_invoice_summary.number_of_bills).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Net Sales per Bill".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_net_per_bill).toLocaleString().padStart(13)}\n`);
    builder.addText(`${" Avg. Sales per Bill".padEnd(25)} : ${Number(datasource.sales_invoice_summary.avg_gross_per_bill).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Payment Method Summary | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    datasource.sales_invoice_payments.forEach(salesInvoicePayment => {
        builder.addText(` ${salesInvoicePayment.name}\n`);
        builder.addText(`${"  - Qty".padEnd(25)} : ${Number(salesInvoicePayment.number_of_invoice).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total".padEnd(25)} : ${Number(salesInvoicePayment.total_amount).toLocaleString().padStart(13)}\n`);
    });
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.gross_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addCut(builder.CUT_FEED);
    
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText("END OF SHIFT REPORT\n");
    builder.addText(`${datasource.store_branch.name}\n`);
    builder.addText(`${datasource.store_branch.address_line_1}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("==========================================\n");
    builder.addText(` Cashier            :    ${datasource.shift.start_by}\n`);
    builder.addText(` Shift In Date      :    ${moment(datasource.shift.from_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Shift Out Date     :    ${moment(datasource.shift.to_date).format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText(` Print Date         :    ${moment().format("DD-MM-YYYY hh:mm")}\n`);
    builder.addText("==========================================\n");
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText(`Sales By Menu | ${moment(datasource.from_date).format("DD-MM-YYYY")}\n`);
    builder.addTextAlign(builder.ALIGN_LEFT);
    builder.addText("------------------------------------------\n");
    datasource.sales_invoice_menus.forEach(salesInvoiceMenu => {
        builder.addText(` ${salesInvoiceMenu.menu.name}\n`);
        builder.addText(`${"  - Qty".padEnd(25)} : ${Number(salesInvoiceMenu.quantity).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total Discount".padEnd(25)} : ${Number(salesInvoiceMenu.total_discount).toLocaleString().padStart(13)}\n`);
        builder.addText(`${"  - Total".padEnd(25)} : ${Number(salesInvoiceMenu.sub_total).toLocaleString().padStart(13)}\n`);
    });
    builder.addText("------------------------------------------\n");
    builder.addText(`${" Total".padEnd(25)} : ${Number(datasource.sales_invoice_summary.net_sales).toLocaleString().padStart(13)}\n`);
    builder.addText("==========================================\n");
    builder.addCut(builder.CUT_FEED);

    print(datasource.station.printer, builder.toString());
}

const print = (printer, ePosBuilder) => {
    var epos = new window.epson.ePOSPrint();

    epos.address = `https://${printer}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
    epos.onreceive = response => console.log(response);
    epos.onerror = error => console.log(error);
    
    epos.send(ePosBuilder);
}