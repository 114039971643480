import md5 from "md5";
import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance from "../serviceFsrClients";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const authorize = createAsyncThunk(
	"auth/vendor/authorize",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request, password: md5(request.password) };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/login", formData));
            
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("Invalid email or password. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);