import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchDiningTableSections } from "../../../store/thunks/diningTableSectionsThunk";
import { fetchMenuCategories } from "../../../store/thunks/menuCategoriesThunk";
import { fetchMenus } from "../../../store/thunks/menusThunk";
import { fetchVoidReasons } from "../../../store/thunks/voidReasonsThunk";
import { fetchSalesOrder, fetchStationOrder, fetchPresettlement, saveOrder, cancelItem, voidSalesOrder } from "../../../store/thunks/salesOrdersThunk";
import { getDiningTableSections, unloadDiningTableSections } from "../../../store/slices/datasources/diningTableSectionsSliceDatasource";
import { getDiningTableEntities, unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import { getMenuCategories, unloadMenuCategories } from "../../../store/slices/datasources/menuCategoriesSliceDatasource";
import { getMenus, getMenuEntites, getMenuPrices, unloadMenus } from "../../../store/slices/datasources/menusSliceDatasource";
import { getSalesOrderBatchEntities, unloadSalesOrderBatches } from "../../../store/slices/datasources/salesOrderBatchesSliceDatasource";
import { getSalesOrderDetailEntities, unloadSalesOrderDetails } from "../../../store/slices/datasources/salesOrderDetailsSliceDatasource";
import { getSalesOrderByCode, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getSalesOrderVoidReasons, unloadVoidReasons } from "../../../store/slices/datasources/voidReasonsSliceDatasource";
import { getCurrentStoreBranch, getWaiter } from "../../helpers/authentication";
import { printStationOrders, printCancelledItem, printPresettlementBill } from "../../helpers/printing";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import FormMoveDiningTable from "./partials/formMoveDiningTable";
import FormPax from "./partials/formPax";
import FormTax from "./partials/formTax";
import FormVoidConfirmation from "./partials/formVoidConfirmation";
import MenuCategories from "./partials/menuCategories";
import Menus from "./partials/menus";
import ShoppingCart from "./partials/shoppingCart";

const FormSalesOrder = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const routeParams = useParams();
    const { salesOrderCode } = routeParams;
    const { id: storeBranchId } = getCurrentStoreBranch();
    const { id: waiterId } = getWaiter();
    const diningTableSections = useSelector(getDiningTableSections);
    const diningTableEntities = useSelector(getDiningTableEntities);
    const menuCategories = useSelector(getMenuCategories);
    const menus = useSelector(getMenus);
    const menuEntities = useSelector(getMenuEntites);
    const menuPrices = useSelector(getMenuPrices);
    const voidReasons = useSelector(getSalesOrderVoidReasons);
    const salesOrderBatches = useSelector(getSalesOrderBatchEntities);
    const salesOrderDetails = useSelector(getSalesOrderDetailEntities);
    const salesOrder = useSelector(getSalesOrderByCode(salesOrderCode));
    const [selectedMenuCategory, setSelectedMenuCategory] = React.useState(null);
    const [diningTableNumber, setDiningTableNumber] = React.useState("");
    const [formMoveDiningTable, setFormMoveDiningTable] = React.useState({ showDialog: false });
    const [formPaxDialog, setFormPaxDialog] = React.useState({ showDialog: false });
    const [formTaxDialog, setFormTaxDialog] = React.useState({ showDialog: false });
    const [formVoidConfirmationDialog, setFormVoidConfirmationDialog] = React.useState({ showDialog: false });
    const [request, setRequest] = React.useState({ 
        id: null, 
        store_branch_id: storeBranchId,
        dining_table_id: null,
        waiter_id: waiterId,
        customer_id: null, 
        void_reason_id: null,
        pax: 1, 
        service_charge_exempt: 0, 
        vat_exempt: 0, 
        description: "", 
        sales_order_batches: [
            {new_row: true, sales_order_details: []}
        ]
    });
    const { setLoading } = props;

    React.useEffect(() => {
        return () => {
            dispatch(unloadDiningTableSections());
            dispatch(unloadDiningTables());
            dispatch(unloadMenuCategories());
            dispatch(unloadMenus());
            dispatch(unloadVoidReasons());
            dispatch(unloadSalesOrderDetails());
            dispatch(unloadSalesOrderBatches());
            dispatch(unloadSalesOrders());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchDiningTableSections(getDiningTableSectionQueryFilter()));
            await dispatch(fetchMenuCategories());
            await dispatch(fetchMenus(getMenuQueryFilter()));
            await dispatch(fetchVoidReasons());
            await dispatch(fetchSalesOrder(getSalesOrderQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, salesOrderCode]); 

    React.useEffect(() => {
		if (salesOrder) {
			const mapViewToModel = {
				id: salesOrder.id,
				dining_table_id: salesOrder.dining_table_id,
				customer_id: salesOrder.customer_id,
				void_reason_id: salesOrder.void_reason_id,
				pax: salesOrder.pax,
				service_charge_exempt: salesOrder.service_charge_exempt,
				vat_exempt: salesOrder.vat_exempt,
				description: salesOrder.description,
				sales_order_batches: mapSalesOrderBatches()
			}

            setDiningTableNumber(diningTableEntities[salesOrder.dining_table_id].number);
			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesOrder]);

    const getDiningTableSectionQueryFilter = () => {
        const queryFilter = {
            storeBranchId: storeBranchId,
            withDiningTables: true
        };

        return {
            query: queryFilter
        };
    }

    const getMenuQueryFilter = () => {
        const queryFilter = {
            withMenuStoreBranch: storeBranchId
        };

        return {
            query: queryFilter
        };
    }

    const getSalesOrderQueryFilter = () => {
        const queryFilter = {
            code: salesOrderCode,
            withCustomer: true,
            withSalesOrderBatches: true,
        };

        return {
            query: queryFilter
        };
    }

    const mapSalesOrderBatches = () => {
		const result = [];
        result.push({ new_row: true, sales_order_details: [] });
        
        if (salesOrder.hasOwnProperty("sales_order_batches")) {
            salesOrder.sales_order_batches.forEach(salesOrderBatchId => {
                const salesOrderBatch = salesOrderBatches[salesOrderBatchId];
                const salesOrderDetail = [];

                salesOrderBatch.sales_order_details.forEach(salesOrderDetailId => {
                    salesOrderDetail.push({
                        id: salesOrderDetails[salesOrderDetailId].id,
                        sales_order_batch_id: salesOrderDetails[salesOrderDetailId].sales_order_batch_id,
                        void_reason_id: salesOrderDetails[salesOrderDetailId].void_reason_id,
                        menu_id: salesOrderDetails[salesOrderDetailId].menu_id,
                        name: menuEntities[salesOrderDetails[salesOrderDetailId].menu_id].name,
                        quantity: salesOrderDetails[salesOrderDetailId].quantity,
                        price: salesOrderDetails[salesOrderDetailId].price,
                        total: salesOrderDetails[salesOrderDetailId].total,
                        discount_percent: salesOrderDetails[salesOrderDetailId].discount_percent,
                        discount_percent_value: salesOrderDetails[salesOrderDetailId].discount_percent_value,
                        discount_value: salesOrderDetails[salesOrderDetailId].discount_value,
                        sub_total: salesOrderDetails[salesOrderDetailId].sub_total,
                        note: salesOrderDetails[salesOrderDetailId].note,
                        is_void: salesOrderDetails[salesOrderDetailId].void_reason_id !== null,
                    })
                })

                result.push({
                    new_row: false,
                    id: salesOrderBatch.id, 
                    batch: salesOrderBatch.batch,
                    ticket: salesOrderBatch.ticket,
                    sales_order_details: [...salesOrderDetail]
                })
            });
        }

		return result;
	}

    const handleUpdatePax = pax => {
        setRequest({...request, pax });
        setFormPaxDialog({showDialog: false});
    }

    const handleCancelUpdatePax = () => {
        setFormPaxDialog({showDialog: false});
    }

    const handleUpdateTax = (serviceChargeExempt, vatExempt) => {
        setRequest({
            ...request, 
            service_charge_exempt: serviceChargeExempt, 
            vat_exempt: vatExempt 
        });
        setFormTaxDialog({showDialog: false});
    }

    const handleCancelUpdateTax = () => {
        setFormTaxDialog({showDialog: false});
    }

    const handleMoveDiningTable = diningTableId => {
        setDiningTableNumber(diningTableEntities[diningTableId].number);
        setRequest({...request, dining_table_id: diningTableId });
        setFormMoveDiningTable({showDialog: false});
    }

    const handleAddOrderItem = menu => {
        const salesOrderBatches = request.sales_order_batches;
        const salesOrderDetails = salesOrderBatches[0].sales_order_details;
        const item = salesOrderDetails.find(item => item.menu_id === menu.id);
        const index = salesOrderDetails.indexOf(item);
        const quantity = index === -1 ? 1 : item.quantity + 1;
        const newItem = {
            menu_id: menu.id,
            name: menu.name,
            quantity: quantity,
            price: menuPrices[menu.id],
            discount_percent: 0,
            discount_value: 0,
            sub_total: quantity * menuPrices[menu.id],
            note: "",
            is_void: false
        };
        
        if (index === -1) {
            salesOrderDetails.push(newItem);
        } else {
            salesOrderDetails[index] = newItem;
        }

       setRequest({ ...request, sales_order_batches: [...salesOrderBatches] });
    }

    const handleUpdateOrderitem = (salesOrderBatchIndex, salesOrderDetailIndex, quantity, note) => {
        if (salesOrderBatchIndex === -1) {
            return;
        }

        const salesOrderBatches = request.sales_order_batches;
        salesOrderBatches[salesOrderBatchIndex].sales_order_details[salesOrderDetailIndex] = {
            ...salesOrderBatches[salesOrderBatchIndex].sales_order_details[salesOrderDetailIndex], 
            quantity, 
            note
        };
        setRequest({
            ...request,
            sales_order_batches: [...salesOrderBatches]
        });
    }

    const handleRemoveOrderItem = salesOrderDetailIndex => {
        const salesOrderBatches = request.sales_order_batches;
        const salesOrderDetails = salesOrderBatches[0].sales_order_details;
        salesOrderDetails.splice(salesOrderDetailIndex, 1);
        setRequest({
            ...request,
            sales_order_batches: [...salesOrderBatches]
        });
    }

    const handleCancelItem = async (salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail, voidReasonId) => {
        setLoading(true);

        await dispatch(cancelItem({ sales_order_id: request.id, store_branch_id: request.store_branch_id, id: salesOrderDetail.id, sales_order_batch_id: salesOrderDetail.sales_order_batch_id, void_reason_id: voidReasonId }))
            .then(unwrapResult)
            .then(response => {
                printCancelledItem(response.cancelledItem);
                setLoading(false);

                const salesOrderBatches = request.sales_order_batches;
                salesOrderBatches[salesOrderBatchIndex].sales_order_details[salesOrderDetailIndex] = {
                    ...salesOrderBatches[salesOrderBatchIndex].sales_order_details[salesOrderDetailIndex], 
                    void_reason_id: response.salesOrder.sales_order_batches[0].sales_order_details[0].void_reason_id,
                    is_void: true, 
                };
                setRequest({
                    ...request,
                    sales_order_batches: [...salesOrderBatches]
                });
            })
            .catch(exception => {
                setLoading(false);
            });
    }

    const handlePrintStationOrder = async batch => {
        setLoading(true);
        
        const query = { id: salesOrder.id, batch: batch };
        await dispatch(fetchStationOrder({ query }))
            .then(unwrapResult)
            .then(response => {
                printStationOrders(response.stationOrders);
                setLoading(false);
            }).catch(exception => {
                setLoading(false);
            });
    }

    const handleCancelMoveDiningTable = () => {
        setFormMoveDiningTable({showDialog: false});
    }

    const handleSelectedMenuCategory = menuCategory => {
        setSelectedMenuCategory(menuCategory);
    }

    const handlePrintPreSettlement = async () => {
        setLoading(true);

        const query = { id: salesOrder.id };
        await dispatch(fetchPresettlement({ query }))
            .then(unwrapResult)
            .then(response => {
                printPresettlementBill(response.presettlement);
                setLoading(false);
            })
            .catch(exception => {
                setLoading(false);
            });
    }

    const handleSplitBill = () => {

    }

    const handleSaveOrder = async () => {
        setLoading(true);
        
        const salesOrderBatches = request.sales_order_batches;
        if (salesOrderBatches[0].sales_order_details.length === 0) {
            salesOrderBatches.splice(0, 1);
        }
        setRequest({
            ...request,
            sales_order_batches: [...salesOrderBatches]
        });
        
		await dispatch(saveOrder(request))
			.then(unwrapResult)
			.then(response => {
                printStationOrders(response.stationOrders);
				setLoading(false);
				history.push('/dashboard');
			})
			.catch(exception => {
				setLoading(false);
			});
    }
    
    const handleVoidSalesOrder = async voidReasonId => {
        setLoading(true);

        setFormVoidConfirmationDialog({showDialog: false});
        await dispatch(voidSalesOrder({ id: request.id, store_branch_id: request.store_branch_id, void_reason_id: voidReasonId }))
            .then(unwrapResult)
            .then(() => {
                setLoading(false);
                history.push('/dashboard');
            })
            .catch(exception => {
                setLoading(false);
            });
    }

    const handleCancelVoidSalesOrder = () => {
        setFormVoidConfirmationDialog({showDialog: false});
    }

    return ( 
        <React.Fragment>
            <PageSubHeader title={`Order #${salesOrderCode}`} />
            <FormPax showDialog={formPaxDialog.showDialog} currentPax={request.pax} handleUpdatePax={handleUpdatePax} handleCloseDialog={handleCancelUpdatePax} />
            <FormTax showDialog={formTaxDialog.showDialog} serviceChargeExempt={request.service_charge_exempt} vatExempt={request.vat_exempt} handleUpdateTax={handleUpdateTax} handleCloseDialog={handleCancelUpdateTax} />
            <FormMoveDiningTable showDialog={formMoveDiningTable.showDialog} currentDiningTable={diningTableEntities[salesOrder?.dining_table_id]} diningTableSections={diningTableSections} diningTables={diningTableEntities} handleMoveDiningTable={handleMoveDiningTable} handleCloseDialog={handleCancelMoveDiningTable} />
            <FormVoidConfirmation showDialog={formVoidConfirmationDialog.showDialog} title={`Cancel Table #${salesOrderCode}`} voidReasons={voidReasons} handleVoid={handleVoidSalesOrder} handleCloseDialog={handleCancelVoidSalesOrder} />
            <div className="widget card w-full h-full">
                <div className="card-body flex h-full" style={{padding: "15px"}}>
                    <div className="flex flex-col w-3/5 mr-5">
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-100 mr-5">
                                    <label className="form-label" htmlFor="dining_table_number">Table</label>
                                    <input id="dining_table_number" name="dining_table_number" type="text" className="form-control form-control-lg h-40" defaultValue={diningTableNumber} readOnly={true} />
                                </div>
                                <div className="w-full mr-5">
                                    <label className="form-label" htmlFor="description">Additional information</label>
                                    <input id="description" name="description" type="text" className="form-control form-control-lg h-40" value={request.description || ""} placeholder="Information will be printed on checker printout" readOnly={true} />
                                </div>
                                <div className="w-70 mr-5">
                                    <label className="form-label">&nbsp;</label>
                                    <button className="btn btn-primary w-full h-40 text-14" onClick={() => setFormPaxDialog({showDialog: true})}>
                                        <i className="fas fa-user" />
                                        {request.pax}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group h-full">
                            {!selectedMenuCategory && <MenuCategories menuCategories={menuCategories} handleSelectedMenuCategory={handleSelectedMenuCategory} />}
                            { selectedMenuCategory && <Menus selectedMenuCategory={selectedMenuCategory} menus={menus[selectedMenuCategory.id]} prices={menuPrices} handleAddOrderItem={handleAddOrderItem} handleSelectedMenuCategory={handleSelectedMenuCategory} />}
                        </div>
                        <div className="form-group" style={{margin: "0"}}>
                            <div className="grid grid-rows-2 grid-flow-col gap-4">
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} disabled={true}>
                                        <i className="fas fa-th-large" />
                                        Merge Table
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-danger text-14 w-full h-40" style={{ display: "inline-block" }} onClick={() => setFormVoidConfirmationDialog({showDialog: true})}>
                                        <i className="fas fa-trash-alt" />
                                        Cancel Table
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} onClick={() => setFormMoveDiningTable({showDialog: true})}>
                                        <i className="fas fa-arrows-alt" />
                                        Move Table
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} disabled={true}>
                                        <i className="fas fa-link" />
                                        Link Table
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} disabled={true}>
                                        <i className="fas fa-exchange-alt" />
                                        Move Item
                                    </button>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-2/5">
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-70 mr-5">
                                    <label className="form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-primary w-full h-40" disabled={true}>
                                        <i className="fas fa-qrcode" style={{margin: "auto"}} />
                                    </button>
                                </div>
                                <div className="w-70 mr-5">
                                    <label className="form-label">&nbsp;</label>
                                    <button className="btn btn-primary w-full h-40" onClick={() => setFormTaxDialog({showDialog: true})}>
                                        <i className="fas fa-percent" style={{margin: "auto"}} />
                                    </button>
                                </div>
                            </div>
                        </div> 
                        <div className="form-group h-full overflow-y-hidden">
                            <div className="classic card h-full">
                                <div className="card-body overflow-y-scroll" style={{padding: "20px"}}>
                                    <ShoppingCart 
                                        menus={menuEntities} 
                                        salesOrderBatches={request.sales_order_batches} 
                                        handleUpdateOrderitem={handleUpdateOrderitem} 
                                        handleRemoveOrderItem={handleRemoveOrderItem} 
                                        handleCancelItem={handleCancelItem} 
                                        handlePrintStationOrder={handlePrintStationOrder} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{margin: "0"}}>
                            <div className="grid grid-rows-2 grid-flow-col gap-4">
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} onClick={handlePrintPreSettlement}>
                                        <i className="fas fa-print" />
                                        Presettlement
                                    </button>
                                </div>
                                <div className="col-span-2">
                                    <NavLink to={`/payment/${salesOrderCode}`} className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }}>
                                        <i className="far fa-dollar-sign" />
                                        Payment
                                    </NavLink>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary text-14 w-full h-40" style={{ display: "inline-block" }} disabled={true} onClick={handleSplitBill}>
                                        <i className="fas fa-receipt" />
                                        Split Bill
                                    </button>
                                </div>
                                <div className="row-span-2">
                                    <button type="button" className="btn btn-primary text-14 w-full h-full" style={{ display: "inline-block" }} onClick={handleSaveOrder}>
                                        <i className="fas fa-save" />
                                        Save Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(FormSalesOrder);