import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchSalesOrder, cancelItem } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.salesOrderBatches);
const salesOrderBatchesSliceDatasource = createSlice({
	name: "datasources/salesOrderBatches",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (salesOrders, _) => {
			dataAdapter.removeAll(salesOrders, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesOrder.fulfilled, (salesOrderBatches, action) => {
                if (action.payload.hasOwnProperty("salesOrderBatches")) {
                    dataAdapter.upsertMany(salesOrderBatches, action.payload.salesOrderBatches);
                }
			})
			.addCase(cancelItem.fulfilled, (salesOrderBatches, action) => {
                if (action.payload.hasOwnProperty("salesOrderBatches")) {
                    dataAdapter.upsertMany(salesOrderBatches, action.payload.salesOrderBatches);
                }
			});
	}
});
export default salesOrderBatchesSliceDatasource.reducer;

export const unloadSalesOrderBatches = () => salesOrderBatchesSliceDatasource.actions.unloaded();
export const getSalesOrderBatchEntities = dataView.selectEntities;