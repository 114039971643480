import { combineReducers } from 'redux';

import storeBranchAuthenticationReducer from "./authentications/storeBranchSliceAuthentication";
import storeAuthenticationReducer from "./authentications/storeSliceAuthentication";
import vendorAuthenticationReducer from "./authentications/vendorSliceAuthentication";
import waterAuthenticationReducer from "./authentications/waiterSliceAuthentication";

import currentShiftCommonReducer from "./commons/currentShiftSliceCommon";
import flashCommonReducer from "./commons/flashSliceCommon";

import customersDatasourceReducer from "./datasources/customersSliceDatasource";
import diningTablesDatasourceReducer from "./datasources/diningTablesSliceDatasource";
import diningTableSectionsDatasourceReducer from "./datasources/diningTableSectionsSliceDatasource";
import menuCategoriesDatasourceReducer from "./datasources/menuCategoriesSliceDatasource";
import menusDatasourceReducer from "./datasources/menusSliceDatasource";
import menuStoreBranchesDatasourceReducer from "./datasources/menuStoreBranchesSliceDatasource";
import paymentCategoriesDatasourceReducer from "./datasources/paymentCategoriesSliceDatasource";
import paymentsDatasourceReducer from "./datasources/paymentsSliceDatasource";
import salesInvoicePaymentsDatasourceReducer from "./datasources/salesInvoicePaymentsSliceDatasource";
import salesInvoiceDetailsDatasourceReducer from "./datasources/salesInvoiceDetailsSliceDatasource";
import salesInvoicesDatasourceReducer from "./datasources/salesInvoicesSliceDatasource";
import salesOrderBatchesDatasourceReducer from "./datasources/salesOrderBatchesSliceDatasource";
import salesOrderDetailsDatasourceReducer from "./datasources/salesOrderDetailsSliceDatasource";
import salesOrdersDatasourceReducer from "./datasources/salesOrdersSliceDatasource";
import shiftDetailsDatasourceReducer from "./datasources/shiftDetailsSliceDatasource";
import shiftsDatasourceReducer from "./datasources/shiftsSliceDatasource";
import storeBranchesDatasourceReducer from "./datasources/storeBranchesSliceDatasource";
import storesDatasourceReducer from "./datasources/storesSliceDatasource";
import voidReasonsDatasourceReducer from "./datasources/voidReasonsSliceDatasource";
import waitersSliceDatasourceReducer from './datasources/waitersSliceDatasource';

export default combineReducers({
	auth: combineReducers({
		store: storeAuthenticationReducer,
		storeBranch: storeBranchAuthenticationReducer,
		vendor: vendorAuthenticationReducer,
		waiter: waterAuthenticationReducer,
	}),
	common: combineReducers({
		currentShift: currentShiftCommonReducer,
		flashMessage: flashCommonReducer,
	}),
	datasources: combineReducers({
		customers: customersDatasourceReducer,
		diningTableSections: diningTableSectionsDatasourceReducer,
		diningTables: diningTablesDatasourceReducer,
		menuCategories: menuCategoriesDatasourceReducer,
		menus: menusDatasourceReducer,
		menuStoreBranches: menuStoreBranchesDatasourceReducer,
		paymentCategories: paymentCategoriesDatasourceReducer,
		payments: paymentsDatasourceReducer,
		salesInvoicePayments: salesInvoicePaymentsDatasourceReducer,
		salesInvoiceDetails: salesInvoiceDetailsDatasourceReducer,
		salesInvoices: salesInvoicesDatasourceReducer,
		salesOrderBatches: salesOrderBatchesDatasourceReducer,
		salesOrderDetails: salesOrderDetailsDatasourceReducer,
		salesOrders: salesOrdersDatasourceReducer,
		shiftDetails: shiftDetailsDatasourceReducer,
		shifts: shiftsDatasourceReducer,
		storeBranches: storeBranchesDatasourceReducer,
		stores: storesDatasourceReducer,
		voidReasons: voidReasonsDatasourceReducer,
		waiters: waitersSliceDatasourceReducer,
	})
});