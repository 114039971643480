import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/salesInvoiceEntity";
import { setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchSalesInvoices = createAsyncThunk(
	"datasources/salesInvoices/fetchSalesInvoices",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-invoices/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesInvoices);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}  
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The sales invoice could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchSalesInvoice = createAsyncThunk(
	"datasources/salesInvoices/fetchSalesInvoice",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-invoices/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesInvoice);

			return { 
				...normalized
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The sales invoice could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchBill = createAsyncThunk(
	"datasources/salesOrders/fetchBill", 
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-invoices/print-bill", {...params, ...fsrHttpConfiguration()}));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The bill could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const splitBill = createAsyncThunk(
	"transaction/salesInvoices/splitBill", 
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-invoices/split-bill", formData, fsrHttpConfiguration()));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be splited. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const payment = createAsyncThunk(
	"transaction/salesInvoices/payment", 
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-invoices/payment", formData, fsrHttpConfiguration()));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be billed. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);