import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMenus } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const menuStoreBranchesSliceDatasource = createSlice({
	name: "datasources/menuStoreBranches",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menuStoreBranches, _) => {
			dataAdapter.removeAll(menuStoreBranches, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenus.fulfilled, (menuStoreBranches, action) => {
                if (action.payload.hasOwnProperty("menuStoreBranches")) {
                    dataAdapter.setAll(menuStoreBranches, action.payload.menuStoreBranches);
                }
			});
	}
});
export default menuStoreBranchesSliceDatasource.reducer;

export const unloadMenuStoreBranches = () => menuStoreBranchesSliceDatasource.actions.unloaded();