import React from "react";
import { paginate } from "../../../helpers/paginate";
import SmallPaginator from "../../elements/smallPaginator";

const Menus = ({ selectedMenuCategory, menus, prices, handleAddOrderItem, handleSelectedMenuCategory }) => {

	const [currentPage, setCurrentPage] = React.useState(1);
	const [pageSize] = React.useState(16);
	const pagesCount = Math.ceil(menus.length / pageSize);

	const getPagedMenus = () => {
		return paginate(menus, currentPage, pageSize);
	}

	const handleOnPrev = () => {
		setCurrentPage(currentPage - 1 <= 0 ? 1 : currentPage - 1);
	}
	
	const handleOnNext = () => {
		setCurrentPage(currentPage + 1 > pagesCount ? pagesCount : currentPage + 1);
	}

	return (
		<React.Fragment>
			<div className="classic card h-full">
				<div className="card-header bg-gray-100 h-65">
					<div className="flex">
						<h3 className="flex card-title items-center h-40">
							{selectedMenuCategory.name}
						</h3>
						<div className="ml-auto">
							<SmallPaginator	
								itemsCount={menus.length} 
								pageSize={pageSize}
								currentPage={currentPage}
								handleOnPrev={handleOnPrev}
								handleOnNext={handleOnNext}
							/>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="h-full grid grid-cols-4 grid-rows-4 gap-10">
						<button className="btn btn-warning" style={{ display: "inline-block" }} onClick={() => handleSelectedMenuCategory(null)}>
							...
						</button>
						{getPagedMenus().map(menu => 
							<button key={menu.id} className="btn btn-primary text-14" style={{display: "block"}} onClick={() => handleAddOrderItem(menu)}>
								{menu.name}
								<br />
								{Number(prices[menu.id]).toLocaleString()}
							</button>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Menus;