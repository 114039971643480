import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchSalesInvoices, fetchSalesInvoice } from "../../thunks/salesInvoicesThunk";
import { fetchSalesOrders, fetchSalesOrder } from "../../thunks/salesOrdersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.waiters);
const waiterSliceDatasource = createSlice({
	name: "datasources/waiters",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (waiters, _) => {
			dataAdapter.removeAll(waiters, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesOrders.fulfilled, (waiters, action) => {
				if (action.payload.hasOwnProperty("waiter")) {
					dataAdapter.setAll(waiters, action.payload.waiter);
				}
			})
			.addCase(fetchSalesOrder.fulfilled, (waiter, action) => {
				if (action.payload.hasOwnProperty("waiter")) {
					dataAdapter.setAll(waiter, action.payload.waiter);
				}
			})
			.addMatcher(isAnyOf(fetchSalesInvoices.fulfilled, fetchSalesInvoice.fulfilled), (waiter, action) => {
				if (action.payload.hasOwnProperty("cashier")) {
					dataAdapter.setAll(waiter, action.payload.cashier);
				}
			});
	}
});
export default waiterSliceDatasource.reducer;

export const unloadWaiters = () => waiterSliceDatasource.actions.unloaded();
export const getWaiterEntities = dataView.selectEntities;