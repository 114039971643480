import React from "react";

const DiningTables = ({ diningTables, dineIn, handleOpenTable }) => {

    const getTableAvailableStatus = diningTableId => {
        return dineIn[diningTableId] ? "btn-success" : "btn-primary";
    }

    return (
        <React.Fragment>
            <div className="flex-grow border m-5 h-full">
                <div className="flex flex-row flex-wrap">
                    {diningTables.map(diningTable => 
                        <React.Fragment key={diningTable.id} >
                            <button className={`btn ${getTableAvailableStatus(diningTable.id)} ml-5 mt-5 mr-2 h-70 w-120`} style={{ display: "inline-block" }} onClick={() => handleOpenTable(diningTable)}>
                                {diningTable.number}
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default DiningTables;