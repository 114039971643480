import React from "react";
import { useSelector } from "react-redux";
import { getVoidReasonEntites, getSalesOrderDetailVoidReasons } from "../../../../store/slices/datasources/voidReasonsSliceDatasource";
import FormEditOrder from "./formEditOrder";
import FormVoidConfirmation from "./formVoidConfirmation";

const ShoppingCart = ({ menus, salesOrderBatches, handleUpdateOrderitem, handleRemoveOrderItem, handleCancelItem, handlePrintStationOrder }) => {
    
    const voidReasonEntities = useSelector(getVoidReasonEntites);
    const voidReasons = useSelector(getSalesOrderDetailVoidReasons);
    const [selectedOrderItem, setSelectedOrderItem] = React.useState({ salesOrderBatchIndex: -1, salesOrderDetailIndex: -1, salesOrderDetail: null });
    const [formEditOrder, setFormEditOrder] = React.useState({ showDialog: false });
    const [formVoidConfirmationDialog, setFormVoidConfirmationDialog] = React.useState({ showDialog: false });

    React.useEffect(() => {
        setSelectedOrderItem({ salesOrderBatchIndex: -1, salesOrderDetailIndex: -1, salesOrderDetail: null });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectedOrderItem = (salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail) => {
        if (salesOrderDetail.is_void) {
            return;
        }

        setSelectedOrderItem({ ...selectedOrderItem, salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail });
        setFormEditOrder({showDialog: true});
    }

    const handleUpdate = (quantity, note) => {
        handleUpdateOrderitem(selectedOrderItem.salesOrderBatchIndex, selectedOrderItem.salesOrderDetailIndex, quantity, note);
        setFormEditOrder({showDialog: false});
    }

    const handleCancelUpdate = () => {
        setFormEditOrder({showDialog: false});
    }

    const handleRemove = (salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail) => {
        setSelectedOrderItem({ ...selectedOrderItem, salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail });
        
        if (salesOrderBatches[salesOrderBatchIndex].new_row) {
            handleRemoveOrderItem(salesOrderDetailIndex);
        } else {
            setFormVoidConfirmationDialog({showDialog: true});
        }
    }

    const handleVoid = voidReasonId => {
        setFormVoidConfirmationDialog({showDialog: false});
        handleCancelItem(
            selectedOrderItem.salesOrderBatchIndex, 
            selectedOrderItem.salesOrderDetailIndex,
            selectedOrderItem.salesOrderDetail, 
            voidReasonId
        );
    }

    const handleCancelVoid = () => {
        setFormVoidConfirmationDialog({showDialog: false});
    }

    return (
        <React.Fragment>
            <FormEditOrder showDialog={formEditOrder.showDialog} salesOrderDetail={selectedOrderItem.salesOrderDetail} handleUpdate={handleUpdate} handleCancelUpdate={handleCancelUpdate} />
            <FormVoidConfirmation showDialog={formVoidConfirmationDialog.showDialog} title={`Cancel Menu (${selectedOrderItem.salesOrderDetail?.name || ""})`} voidReasons={voidReasons} handleVoid={handleVoid} handleCancelVoid={handleCancelVoid} />
            <table className="table">
                <tbody>
                    {salesOrderBatches.map((salesOrderBatch, salesOrderBatchIndex) =>
                        <React.Fragment key={`sales_order_batch_${salesOrderBatchIndex}`}>
                            {
                                salesOrderBatch.new_row ? 
                                    <React.Fragment>
                                        {salesOrderBatch.sales_order_details.length > 0 && 
                                            <tr className="text-14 h-45">
                                                <td colSpan="3">
                                                    <strong>New Order</strong>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                : 
                                    <React.Fragment>
                                        <tr className="text-14">
                                            <td colSpan="2">
                                                <strong className="text-14">Order: {Number(salesOrderBatch.batch).toLocaleString()}</strong>
                                            </td>
                                            <td className="text-right">
                                                <button type="button" className="btn btn-primary btn-icon" onClick={() => handlePrintStationOrder(salesOrderBatch.batch)}>
                                                    <span className="las la-print" />
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                            }
                            {
                                salesOrderBatch.sales_order_details.map((salesOrderDetail, salesOrderDetailIndex) => 
                                    <tr key={`sales_order_detail_${salesOrderDetailIndex}`} className={`text-14 cursor-pointer ${salesOrderDetail.is_void && "text-red-700"}`}>
                                        <td onClick={() => handleSelectedOrderItem(salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail)}>
                                            {Number(salesOrderDetail.quantity).toLocaleString()}
                                        </td>
                                        <td onClick={() => handleSelectedOrderItem(salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail)}>
                                            {menus[salesOrderDetail.menu_id]?.name}
                                            <br />
                                            @ <strong>{Number(salesOrderDetail.price).toLocaleString()}</strong> | <strong>Total: {Number(salesOrderDetail.sub_total).toLocaleString()}</strong>
                                            {!salesOrderDetail.is_void &&salesOrderDetail.note !== "" && 
                                                <React.Fragment>
                                                    <br />
                                                    <span className="text-blue-500">*{salesOrderDetail.note}</span>
                                                </React.Fragment>
                                            }
                                            {salesOrderDetail.is_void && 
                                                <React.Fragment>
                                                <br />
                                                ** Cancelled **
                                                <br />
                                                {voidReasonEntities[salesOrderDetail.void_reason_id].reason}
                                            </React.Fragment>
                                            }
                                        </td>
                                        <td className="text-right">
                                            {!salesOrderDetail.is_void && 
                                                <button type="button" className="btn btn-danger btn-icon" onClick={() => handleRemove(salesOrderBatchIndex, salesOrderDetailIndex, salesOrderDetail)}>
                                                    <span className="las la-trash-alt" />
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </React.Fragment>
    );
}
 
export default ShoppingCart;