import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchVoidReasons } from "../../thunks/voidReasonsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.voidReasons);
const voidReasonsSliceDatasource = createSlice({
	name: "datasources/voidReasons",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (voidReasons, _) => {
			dataAdapter.removeAll(voidReasons, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchVoidReasons.fulfilled, (voidReasons, action) => {
				if (action.payload.hasOwnProperty("voidReasons")) {
					dataAdapter.setAll(voidReasons, action.payload.voidReasons);
				}
			});
	}
});
export default voidReasonsSliceDatasource.reducer;

export const unloadVoidReasons = () => voidReasonsSliceDatasource.actions.unloaded();
export const getVoidReasonEntites = dataView.selectEntities;
export const getSalesOrderVoidReasons = createSelector(
	dataView.selectAll, 
	voidReasons => voidReasons.filter(voidReason => voidReason.category === "Sales Order")
);
export const getSalesOrderDetailVoidReasons = createSelector(
	dataView.selectAll, 
	voidReasons => voidReasons.filter(voidReason => voidReason.category === "Sales Order Detail")
);