import React from "react";
import moment from "moment";
import Cleave from 'cleave.js/react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchPaymentCategories } from "../../../store/thunks/paymentCategoriesThunk";
import { fetchPayments } from "../../../store/thunks/paymentsThunk";
import { fetchSalesOrder } from "../../../store/thunks/salesOrdersThunk";
import { payment } from "../../../store/thunks/salesInvoicesThunk";
import { getDiningTableEntities, unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import { getWaiterEntities, unloadWaiters } from "../../../store/slices/datasources/waitersSliceDatasource";
import { getPaymentCategories, unloadPaymentCategories } from "../../../store/slices/datasources/paymentCategoriesSliceDatasource";
import { getPayments, getPaymentEntities, unloadPayments } from "../../../store/slices/datasources/paymentsSliceDatasource";
import { getMenuEntites, unloadMenus } from "../../../store/slices/datasources/menusSliceDatasource";
import { getSalesOrderDetailEntities, unloadSalesOrderDetails } from "../../../store/slices/datasources/salesOrderDetailsSliceDatasource";
import { getSalesOrderByCode, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getWaiter } from "../../helpers/authentication";
import { printBill } from "../../helpers/printing";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import PaymentCategories from "./partials/paymentCategroies";
import Payments from "./partials/payments";
import FormPaymentConfirmation from "./partials/formPaymentConfirmation";
import PaymentTypeCash from "./partials/paymentTypeCash";
import PaymentTypeCard from "./partials/paymentTypeCard";
import PaymentTypeOther from "./partials/paymentTypeOther";

const Payment = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const routeParams = useParams();
    const { salesOrderCode } = routeParams;
    const { id: waiterId } = getWaiter();
    const diningTableEntities = useSelector(getDiningTableEntities);
    const waiterEntities = useSelector(getWaiterEntities);
    const paymentCategories = useSelector(getPaymentCategories);
    const payments = useSelector(getPayments);
    const paymentEntities = useSelector(getPaymentEntities);
    const menus = useSelector(getMenuEntites);
    const salesOrderDetails = useSelector(getSalesOrderDetailEntities);
    const salesOrder = useSelector(getSalesOrderByCode(salesOrderCode));
    const [selectedPaymentCategory, setSelectedPaymentCategory] = React.useState(null);
    const [selectedPayment, setSelectedPayment] = React.useState(null);
    const [formPaymentConfirmationDialog, setFormPaymentConfirmationDialog] = React.useState({ showDialog: false, showProcess: false });
    const [request, setRequest] = React.useState({ 
        id: '',
        sales_order_id: null, 
        cashier_id: waiterId,
        customer_id: null, 
        total_payment: 0,
        change: 0,
        sales_invoice_payments: []
    });
    const { setLoading } = props;

    React.useEffect(() => {
        return () => {
            dispatch(unloadDiningTables());
            dispatch(unloadWaiters());
            dispatch(unloadPaymentCategories());
            dispatch(unloadPayments());
            dispatch(unloadMenus());
            dispatch(unloadSalesOrderDetails());
            dispatch(unloadSalesOrders());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchPaymentCategories());
            await dispatch(fetchPayments());
            await dispatch(fetchSalesOrder(getSalesOrderQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, salesOrderCode]);
    
    React.useEffect(() => {
		if (salesOrder) {
			const mapViewToModel = {
				sales_order_id: salesOrder.id,
				customer_id: salesOrder.customer_id,
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesOrder]);

    const getSalesOrderQueryFilter = () => {
        const queryFilter = {
            code: salesOrderCode,
            withDiningTable: true,
            withCustomer: true,
            withWaiter: true,
            withSalesOrderDetails: true
        };

        return {
            query: queryFilter
        };
    }
    
    const handleSelectedPaymentCategory = paymentCategoryId => {
        setSelectedPaymentCategory(paymentCategoryId);
    }

    const handleSelectedPayment = payment => {
        setSelectedPayment(payment);
    }
    
    const handleAddPayment = (cardNumber, accountName, approvalCode, amount, note) => {
        const salesInvoicePayments = request.sales_invoice_payments;
        
        salesInvoicePayments.push({ 
            payment_id: selectedPayment.id, 
            card_number: cardNumber, 
            account_name: accountName, 
            approval_code: approvalCode, 
            amount: amount, 
            note: note 
        });

        const totalPayment = salesInvoicePayments.reduce((total, salesInvoicePayment) => total = total + parseFloat(salesInvoicePayment.amount), 0);
        const change = totalPayment - salesOrder.grand_total;
        setSelectedPayment(null);
        setRequest({
            ...request,
            total_payment: totalPayment,
            change: change,
            sales_invoice_payments: [...salesInvoicePayments] 
        });
    }

    const handleCancelPayment = () => {
        setSelectedPayment(null);
    }

    const handleRemovePayment = index => {
        const salesInvoicePayments = request.sales_invoice_payments;
        salesInvoicePayments.splice(index, 1);

        const totalPayment = salesInvoicePayments.reduce((total, salesInvoicePayment) => total = total + parseFloat(salesInvoicePayment.amount), 0);
        const change = totalPayment - salesOrder.grand_total;
        setRequest({ 
            ...request, 
            total_payment: totalPayment,
            change: change <= 0 ? 0 : change,
            sales_invoice_payments: [...salesInvoicePayments] 
        });
        setFormPaymentConfirmationDialog({showDialog: false, showProcess: false});
    }

    const handleCloseDialog = () => {
        setFormPaymentConfirmationDialog({showDialog: false, showProcess: false});
    }

    const handleSavePayment = async () => {
        setLoading(true);

        await dispatch(payment(request))
			.then(unwrapResult)
			.then(response => {
				setLoading(false);
                printBill(response.bill);
                setFormPaymentConfirmationDialog({showDialog: false, showProcess: false});
				history.push('/dashboard');
			})
			.catch(exception => {
				setLoading(false);
			});
    }

    return (
        <React.Fragment>
            <PageSubHeader title={`Order Payment: ${salesOrderCode}`} />
            <FormPaymentConfirmation 
                showDialog={formPaymentConfirmationDialog.showDialog} 
                showProcess={formPaymentConfirmationDialog.showProcess} 
                outstanding={salesOrder?.grand_total} 
                totalPayment={request.total_payment} 
                change={request.change} 
                payments={paymentEntities} 
                paymentDetails={request.sales_invoice_payments} 
                handleRemovePayment={handleRemovePayment} 
                handleSavePayment={handleSavePayment} 
                handleCloseDialog={handleCloseDialog} 
            />
            {selectedPayment && <PaymentTypeCash showDialog={selectedPayment.payment_model === 1} subTotal={salesOrder.grand_total} handleAddPayment={handleAddPayment} handleCloseDialog={handleCancelPayment} />}
            {selectedPayment && <PaymentTypeCard showDialog={selectedPayment.payment_model === 2} subTotal={salesOrder.grand_total} handleAddPayment={handleAddPayment} handleCloseDialog={handleCancelPayment} />}
            {selectedPayment && <PaymentTypeOther showDialog={selectedPayment.payment_model === 3} subTotal={salesOrder.grand_total} handleAddPayment={handleAddPayment} handleCloseDialog={handleCancelPayment} />}
            <div className="widget card w-full h-full">
                <div className="card-body flex h-full" style={{padding: "15px"}}>
                    <div className="flex flex-col w-3/4 mr-5">
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-100 mr-5">
                                    <label className="form-label" htmlFor="dining_table_number">Table</label>
                                    <input id="dining_table_number" name="dining_table_number" type="text" className="form-control form-control-lg h-40" defaultValue={salesOrder && diningTableEntities[salesOrder.dining_table]?.number} readOnly={true} />
                                </div>
                                <div className="w-full mr-5">
                                    <label className="form-label" htmlFor="customer_id">Member</label>
                                    <input id="customer_id" name="customer_id" type="text" className="form-control form-control-lg h-40" value={request.customer_id || ""} placeholder="- Select Member -" readOnly={true} />
                                </div>
                                <div className="w-70 mr-5">
                                    <label className="form-label">&nbsp;</label>
                                    <button className="btn btn-primary w-full h-40 text-14">
                                        <i className="fas fa-user" style={{margin: "auto"}} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group h-full">
                            {!selectedPaymentCategory && <PaymentCategories paymentCategories={paymentCategories} handleSelectedPaymentCategory={handleSelectedPaymentCategory} />}
                            { selectedPaymentCategory && <Payments payments={payments[selectedPaymentCategory.id]} handleSelectedPayment={handleSelectedPayment} handleSelectedPaymentCategory={handleSelectedPaymentCategory} />}
                        </div>
                        <div className="form-group" style={{margin: "0"}}>
                            <div className="grid grid-rows-2 grid-flow-col gap-4">
                                <div>   
                                    <label className="form-label">Outstanding</label>
                                    <Cleave className="form-control form-control-lg text-right" disabled={true} value={salesOrder && salesOrder.grand_total} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                </div>
                                <div>&nbsp;</div>
                                <div>
                                    <label className="form-label">Total Payment</label>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-primary input-group-item" onClick={() => setFormPaymentConfirmationDialog({showDialog: true, showProcess: false})}>
                                            <i className="fas fa-info-circle" style={{margin: "auto"}} />
                                        </button>
                                        <Cleave className="form-control form-control-lg input-group-item text-right" disabled={true} value={request.total_payment} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label">Change</label>
                                    <Cleave className="form-control form-control-lg text-right" disabled={true} value={request.change} style={{fontWeight: "900"}} options={{numeral: true, numeralThousandsGroupStyle: "thousand"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/4">
                        <div className="form-group h-full overflow-y-hidden mt-28 pb-18">
                            <div className="classic card h-full">
                                <div className="card-body overflow-y-scroll" style={{padding: "20px"}}>
                                    <table className="bill-table">
                                        <tbody>
                                            <tr className="text-13">
                                                <td width="10%">Order</td>
                                                <td colSpan="2">: &nbsp; {salesOrder && salesOrder.code}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td width="10%">Date</td>
                                                <td colSpan="2">: &nbsp; {salesOrder && moment(salesOrder.order_date).format("DD-MM-YYYY")}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td width="10%">Table</td>
                                                <td colSpan="2">: &nbsp; {salesOrder && diningTableEntities[salesOrder.dining_table]?.number}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td width="10%">Pax</td>
                                                <td colSpan="2">: &nbsp; {salesOrder && Number(salesOrder.pax).toLocaleString()}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td width="10%">Waiter</td>
                                                <td colSpan="2">: &nbsp; {salesOrder && waiterEntities[salesOrder.waiter_id]?.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="separator" colSpan="3"></td>
                                            </tr>
                                            {salesOrder && salesOrder.sales_order_details && salesOrder.sales_order_details.map(id =>
                                                <tr key={id} className="text-13">
                                                    <td width="5%">{Number(salesOrderDetails[id].quantity).toLocaleString()}</td>
                                                    <td>
                                                        {menus[salesOrderDetails[id].menu_id].name}
                                                    </td>
                                                    <td width="1%" className="text-right">
                                                        {Number(salesOrderDetails[id].sub_total).toLocaleString()}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="separator" colSpan="3"></td>
                                            </tr>
                                            <tr className="text-13">
                                                <td className="text-right" colSpan="2">Subtotal &nbsp;</td>
                                                <td width="1%" className="text-right">{salesOrder && Number(salesOrder.sub_total).toLocaleString()}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td className="text-right" colSpan="2">SC &nbsp;</td>
                                                <td width="1%" className="text-right">{salesOrder && Number(salesOrder.service_charge_percent_value).toLocaleString()}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td className="text-right" colSpan="2">Tax &nbsp;</td>
                                                <td width="1%" className="text-right">{salesOrder && Number(salesOrder.vat_percent_value).toLocaleString()}</td>
                                            </tr>
                                            <tr className="text-13">
                                                <td className="text-right" colSpan="2">Grand Total &nbsp;</td>
                                                <td width="1%" className="text-right">{salesOrder && Number(salesOrder.grand_total).toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{margin: "0"}}>
                            <button type="button" className="btn btn-primary text-14 w-full h-46 " style={{ display: "inline-block" }} onClick={() => setFormPaymentConfirmationDialog({showDialog: true, showProcess: true})}>
                                <i className="fas fa-save" />
                                Save Payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Payment);