import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchShifts, fetchShift, changeShift } from "../../thunks/shiftsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.shifts);
const shiftsSliceDatasource = createSlice({
	name: "datasources/shifts",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (shifts, _) => {
			dataAdapter.removeAll(shifts, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchShifts.fulfilled, (shifts, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					shifts.pagination = action.payload.pagination;
				}

				if (action.payload.hasOwnProperty("shifts")) {
					dataAdapter.setAll(shifts, action.payload.shifts);
				}
			})
			.addCase(fetchShift.fulfilled, (shift, action) => {
				if (action.payload.hasOwnProperty("shift")) {
					dataAdapter.upsertMany(shift, action.payload.shift);
				}
			})
			.addCase(changeShift.fulfilled, (shift, action) => {
				if (action.payload.hasOwnProperty("shift")) {
					dataAdapter.upsertMany(shift, action.payload.shift);
				}
			});
	}
});
export default shiftsSliceDatasource.reducer;

export const unloadShifts = () => shiftsSliceDatasource.actions.unloaded();
export const getShifts = dataView.selectAll;
export const getShiftEntities = dataView.selectEntities;
export const getShiftPagination = createSelector(
	state => state.datasources.shifts.pagination,
	pagination => pagination
);
export const getShift = createSelector(
	dataView.selectAll,
	shift => shift[0]
);